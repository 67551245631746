import { JsonRpc, RpcError } from 'eosjs';
import allKPopButton from '../images/ss_allkpopButton.png';
import bitBoyButton from '../images/ss_bitboyButton.png';
import funkyButton from '../images/ss_FunkySmall.png';
import plutoButton from '../images/ss_plutoButton.png';
import stacheButton from '../images/ss_stacheButton.png';
import starsheepButton from '../images/starsheepButtonSmall.png';
import animatedPunkzButton from '../images/ss_AnimatedPunkzButtonSmall.png';
import metaMayhemButton from '../images/metaMayhemButton.png';
import celticButton from '../images/ss_celticButton.png';
import darkMatterButton from '../images/darkMatterButton.png';
import elongateButton from '../images/elongateButton.png';
import beyondFomoButton from '../images/beyondFOMOButton.png';
import electraButton from '../images/electraButton.png';
import tcgButton from '../images/tcgButton.png';
import dexterButton from '../images/dexterButton.png';
import nfgButton from '../images/nfgButton.png';
import templeFehuButton from '../images/templeFehuButton.png';
import arcadeButton from '../images/arcadeButton.png';
import vikingButton from '../images/viking.png';
import treeOfLifeButton from '../images/treeOfLifeButton.png';
import lavaHeads from '../images/lavaheads.png';
import dragons from '../images/dragons.png';
import jrsLandsButton from '../images/jrsLandButton.png';
import starshipButton from '../images/ss_StarshipButtonSmall.png';
import ssgButton from '../images/ss_SSGButtonSmall.png';
import pawthButton from '../images/ss_PawthereumButtonSmall.png';
import cyberhornetsButton from '../images/ss_CyberHornetsButtonSmall.png';

export function getPlanetDictionary(planet_id) {
 const spacestation_multiplier = 16384;
 const quad_multiplier = 4096;
 const sector_multiplier = 128;
 const system_multiplier = 4;
 const planet_multiplier = 1;

 const spacestation = Math.floor(planet_id / spacestation_multiplier);
 const quad = Math.floor( (planet_id - (spacestation * spacestation_multiplier)) / quad_multiplier );
 const sector = Math.floor( (planet_id - (spacestation * spacestation_multiplier) - (quad * quad_multiplier)) / sector_multiplier );
 const system = Math.floor( (planet_id - (spacestation * spacestation_multiplier) - (quad * quad_multiplier) - (sector * sector_multiplier)) / system_multiplier );
 const planet = Math.floor( (planet_id - (spacestation * spacestation_multiplier) - (quad * quad_multiplier) - (sector * sector_multiplier) - (system * system_multiplier)) / planet_multiplier );

 return {
   'spaceStation': spacestation,
   'quadrant': quad,
   'sector': sector,
   'system': system,
   'planet': planet
 }
}

export function quadrant(id) {
  switch(id) {
    case 0:
      return 'North';
    case 1:
      return 'West';
    case 2:
      return 'East';
    case 3:
      return 'South'
  }
}

export async function getFleetRPC(waxId) {
  // let endPoints = getEndPoints();
  // let endPoint = endPoints[Math.floor(Math.random() * endPoints.length)];
  // let rpc = new JsonRpc(endPoint);
  let rpc = new JsonRpc(sessionEndpoint);

  // console.log(`Fetching fleet information from ${endPoint} via RPC.`);
  console.log(`Fetching fleet information from ${sessionEndpoint} via RPC.`);

  let result = await rpc.get_table_rows({
    json: true,
    code: 'starshipgame',
    scope: 'starshipgame',
    table: 'starshipdata',
    limit: 100,
    lower_bound: waxId,
    upper_bound: waxId,
    index_position: 2,
    key_type: 'name',
    reverse: false,
    show_payer: false
  });

  console.debug('RPC Results:', result);

  return result;
}

export function isProd() {
	const env = process.env.REACT_APP_ENV || 'development';
	return env === 'production';
  // if (window.location.hostname === 'play.deploystarship.com' || window.location.hostname === 'chic-centaur-7b4df9.netlify.app') {
  //   return true;
  // } else {
  //   return false;
  // }
}

export function enviromentVariables() {
  let envVars = {};

  if (isProd()) {
    envVars.chainId = '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4';
    // envVars.nodeUrl = 'https://wax.greymass.com';
    // envVars.contract = 'starshipgame';
    // envVars.backend = 'starship-backend.herokuapp.com';
    envVars.atomichub = 'wax.api.atomicassets.io';
    // envVars.ipfs = 'ipfs.io';
  } else {
    envVars.chainId = 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12';
    // envVars.nodeUrl = 'https://waxtestnet.greymass.com';
    // envVars.contract = 'starshipnfts';
    // envVars.backend ='starship-backend-test-app-1-e6iep.ondigitalocean.app';
    envVars.atomichub = 'test.wax.api.atomicassets.io';
    // envVars.ipfs = 'test.ipfs.infura.io'; //doesnt work with our NFTs

  }
	envVars.ipfs = 'ipfs.io';
	envVars.contract = process.env.REACT_APP_CONTRACT;
	envVars.collectionName = process.env.REACT_APP_COLLECTION_NAME;
	envVars.backend = process.env.REACT_APP_BACKEND_URL;
	envVars.maxSpacestations = process.env.REACT_APP_MAX_SPACESTATIONS;

  return envVars;
}

export function getEndPoints() {
	if (isProd()) {
		return [
			'https://wax.pink.gg', //cors issues on test-mainnet
			// 'https://wax.cryptolions.io', //blocked by cors
			//'https://wax.eosphere.io',
			// 'https://wax.greymass.com',
			'https://api.waxsweden.org',
			'https://wax.eu.eosamsterdam.net'
		];
	} else {
		return [
			'https://testnet.wax.pink.gg',
			'https://wax-testnet.cryptolions.io',
			// 'https://waxtestnet.greymass.com', //blocked by cors
			//'https://agile-plateau-18437.herokuapp.com/https://waxtestnet.greymass.com'
			// 'https://agile-plateau-18437.herokuapp.com/https://testnet.wax.pink.gg',
			// 'https://agile-plateau-18437.herokuapp.com/https://testnet.waxsweden.org',
			// 'https://agile-plateau-18437.herokuapp.com/https://wax-testnet.eosphere.io',
			// 'https://agile-plateau-18437.herokuapp.com/https://wax-testnet.cryptolions.io',
		];
	}
}

export function getRandomEndpoint() {
	const endpoints = getEndPoints();
	let randomIndex = Math.floor(Math.random() * endpoints.length);
	if(randomIndex >= endpoints.length) {
		randomIndex = endpoints.length-1;
	}
	return getEndPoints()[randomIndex];
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function rarity(index) {
  try {
    let i = parseInt(index, 10);

    switch (i) {
      case 0:
        return 'Common';
      case 1:
        return 'Uncommon';
      case 2:
        return 'Rare';
      case 3:
        return 'Epic';
      case 4:
        return 'Legendary';
      case 5:
        return 'Mythic';
      case 6:
        return 'Primordial';
    }
  } catch(ex) {
    console.error(ex);
    return 'NaN';
  }
}

export function spaceStationInfo() {
  let spacestations = [
    { // 0
      name: 'Allkpop',
      image: allKPopButton,
      background: 'allkpop.jpg'
    },
    { // 1
      name: 'Pluto Alliance',
      image: plutoButton,
      background: 'pluto.jpg'
    },
    { // 2
      name: 'Bitboy',
      image: bitBoyButton,
      background: 'bitboy.jpg'
    },
    { // 3
      name: 'Starsheep Colony',
      image: starsheepButton,
      background: 'SS_starsheep.jpg'
    },
    { // 4
      name: 'Cryptostache',
      image: stacheButton,
      background: 'stache.jpg'
    },
    { // 5
      name: 'DrunkenPunkz Cantina',
      image: starshipButton,
      background: 'SS_Template.jpg'
    },
    { // 6
      name: 'Funky Monkey Frat House',
      image: funkyButton,
      background: 'ss_Funky.jpg'
    },
    { // 7
      // name: 'Meta Mayhem',
      // image: metaMayhemButton,
      // background: 'SS_MetaMayhem.jpg'
	    name: 'The Hive',
	    image: cyberhornetsButton,
	    background: 'SS_CyberHornets.jpg'
    },
    { // 8
      name: 'Lava Heads',
      image: lavaHeads,
      background: 'SS_metaMayhemAlpha.jpg'
    },
	  { // 9
	    name: 'Animated Punkz',
	    image: animatedPunkzButton,
	    background: 'SS_AnimatedPunkz.jpg'
	  },
    { // 10
      name: 'Celtic Alliance',
      image: celticButton,
      background: 'SS_Celtic.jpg'
    },
    { // 11
      name: 'Dark Matter',
      image: darkMatterButton,
      background: 'ss_DarkMatter.jpg'
    },
    { // 12
      name: 'Elongate',
      image: elongateButton,
      background: 'elongate.jpg'
    },
    { // 13
      name: 'BeyondFOMO',
      image: beyondFomoButton,
      background: 'BeyondFOMO.jpg'
    },
    { // 14
      name: 'Electra Protocol',
      image: electraButton,
      background: 'electra.jpg'
    },
    { // 15
      name: 'TCG World',
      image: tcgButton,
      background: 'tcg.jpg'
    },
    { // 16
      name: 'Dexter Plays PH',
      image: dexterButton,
      background: 'dexter.jpg'
    },
    { // 17
      name: 'Non Fungible Gang',
      image: nfgButton,
      background: 'nfg.jpg'
    },
    { // 18
      name: 'Temple Fehu',
      image: templeFehuButton,
      background: 'templeFehu.jpg'
    },
    { // 19
      name: 'The Galactic Archade',
      image: arcadeButton,
      background: 'arcade.jpg'
    },
    { // 20
      name: 'Halfdead\'s Valhalla',
      image: vikingButton,
      background: 'vikingBackground.gif'
    },
    { // 21
      name: 'Founder\'s Legacy',
      image: treeOfLifeButton,
      background: 'treeOfLife.jpg'
    },
    { // 22
      name: 'JR\'s Lands',
      image: jrsLandsButton,
      background: 'jrsland.jpg'
    },
    { // 23
      name: 'Starship Gang',
      image: ssgButton,
      background: 'ssg.jpg'
    },
    { // 24
      name: 'Pawthereum',
      image: pawthButton,
      background: 'SS_Pawthereum.jpg'
    }
  ];

  return isProd() ? spacestations : spacestations.slice(0, enviromentVariables().maxSpacestations);
}

export function convertUnixTime(unix) {
  let a = new Date(unix * 1000),
      year = a.getFullYear(),
      months = ['January','February','March','April','May','June','July','August','September','October','November','December'],
      month = months[a.getMonth()],
      date = a.getDate(),
      hour = a.getHours(),
      min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes(),
      sec = a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds();
  return `${month} ${date}, ${year} ${hour}:${min}:${sec}`;
}

export function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var days = ~~(duration / 3600);
    var hours = ~~((duration % 3600) / 60);
    var minutes = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (days > 0) {
        if (days === 1) {
          ret += days + " day " + (hours < 10 ? "0" : "");
        } else {
          ret += days + " days " + (hours < 10 ? "0" : "");
        }
    }

    if (hours > 0) {
      if (hours === 1) {
        ret += hours + " hour " + (minutes < 10 ? "0" : "");
      } else {
        ret += hours + " hours " + (minutes < 10 ? "0" : "");
      }
    }

    if (minutes === 1) {
      ret += minutes + " minute ";
    } else {
      ret += minutes + " minutes ";
    }

    return ret;
}

export function getMinDiff(startDate, endDate) {
  const msInMinute = 60 * 1000;

  return Math.round(
    Math.abs(endDate - startDate) / msInMinute
  );
}

export const sessionEndpoint = getRandomEndpoint();
export const contractAddress = enviromentVariables().contract;
export const collectionName = enviromentVariables().collectionName;
export const ipfsNode = enviromentVariables().ipfs;

console.info('** SESSION ENDPOINT **', sessionEndpoint);
