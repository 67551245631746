import React, { useState, useEffect } from 'react';
import {collectionName, contractAddress} from '../../helpers';
import Launcher from '../launcher';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import '@neftyblocks/market';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import './shop.scss';

export default function Shop(props) {
  const setGameScreen = props.setGameScreen;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const availableBalance = props.availableBalance;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const toast = props.toast;
  const fleet = props.fleet;
  const getFleet = props.getFleet;
  const selectedStarShip = props.selectedStarShip;
  const setSelectedStarShip = props.setSelectedStarShip;
  const shipData = props.shipData;
  const getShipData = props.getShipData;

  let backgroundImage = (
    <img src="shop.jpg" className="desktopBackground" />
  );

  return (
    <div>
      <div className="divShopDesktop">
        <div className="divDesktopBackground">
          {backgroundImage}
        </div>

        <div className="divDesktopContent">
          <div className="divShopDesktopContent">
            <neftyblocks-market collection={collectionName}></neftyblocks-market>
          </div>
        </div>

        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <img src={controlPanelBackground} alt="" />
          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
              let waxUserAccount = '';
              let player = '';
              if (anchorWax) {
                let { session } = identity;
                waxUserAccount = session.auth.actor;
                player = session.auth.actor;
              } else {
                waxUserAccount = wax.userAccount;
                player = wax.userAccount;
              }

              let actions = [
                {
                  account: contractAddress,
                  name: 'claim',
                  authorization: [{
                    actor: waxUserAccount,
                    permission: 'active',
                  }],
                  data: {
                    player: player
                  },
                }
              ];

              try {
                let result;
                if (cloudWallet) {
                  result = await wax.api.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  });
                } else {
                  let { session } = identity;
                  result = await session.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  })
                }

                toast.success('Kyanite Claimed!',{
                  position: toast.POSITION.TOP_CENTER
                });
              } catch(ex) {
                toast.error(ex,{
                  position: toast.POSITION.TOP_CENTER
                });
              }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>
      </div>

      <div className="divShopMobile">
        <div>
          <h1 className="screenTitle">Shop</h1>
          <neftyblocks-market collection={collectionName}></neftyblocks-market>
        </div>
      </div>
    </div>
  );
}
