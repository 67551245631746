import React from 'react';

export default function MobileFooter(props) {
  return (
    <div class="footer">
      <div>
        <hr />
        <p>All unowned planet pools are 20% or less of max now.</p>
        <p>Buy grasslands at Founders Legacy for a chance to <a href="https://twitter.com/DepStarShipCEO/status/1532796655090778114?t=Qtss-dx92cYXSDEIiA4g_w&s=19" target="_new">win big</a>!</p>
      </div>
      <div style={{textAlign: 'center'}}>
        &copy; {new Date().getFullYear()} Starship LLC
      </div>
    </div>
  );
}
