import React, { useState, useEffect } from 'react';
import WorkerBuilder from '../../workers/worker-builder';
import FetchEquippedPartsWorker from '../../workers/fetchEquippedParts.worker.js';
import FetchDestinationPlanet from '../../workers/fetchDestinationPlanet.worker.js';
import smallShipCommonImage from '../../images/shipCommonSmall.png';
import smallShipUncommonImage from '../../images/shipUncommonSmall.png';
import smallShipRareImage from '../../images/shipRareSmall.png';
import smallShipEpicImage from '../../images/shipEpicSmall.png';
import smallShipLegendaryImage from '../../images/shipLegendarySmall.png';
import smallShipMythicImage from '../../images/shipMythicSmall.png';
import smallShipFounderImage from '../../images/shipFounderSmall.png';

export default function AvailableShip(props) {
  const starShipData = props.starShipData;
  const setSelectedStarShip = props.setSelectedStarShip;
  const destinationPlanet = props.destinationPlanet;
  const [shipData, setShipData] = useState({});
  const [fetchingShipData, setFetchingShipData] = useState(false);
  const [fetchingDestinationPlanetData, setFetchingDestinationPlanetData] = useState(false);
  const [fetchingPlanetData, setFetchingPlanetData] = useState(false);
  const selectedStarShip = props.selectedStarShip;

  let shipImage = '';
  let bouncyStyle = {};
  
  if (selectedStarShip === starShipData.key) {
    bouncyStyle = {
      animation: 'pulse 1s infinite'
    }
  }


  


  



  switch(starShipData.rarity) {
    case 0:
      shipImage = (
        <img src={smallShipCommonImage} alt="Common" />
      );
      break;
    case 1:
      shipImage = (
        <img src={smallShipUncommonImage} alt="Uncommon" />
      );
      break;
    case 2:
      shipImage = (
        <img src={smallShipRareImage} alt="Rare" />
      );
      break;
    case 3:
      shipImage = (
        <img src={smallShipEpicImage} alt="Epic" />
      );
      break;
    case 4:
      shipImage = (
        <img src={smallShipLegendaryImage} alt="Legendary" />
      );
      break;
    case 5:
      shipImage = (
        <img src={smallShipMythicImage} alt="Mythic" />
      );
      break;
    case 6:
      shipImage = (
        <img src={smallShipFounderImage} alt="Founder" />
      );
      break;
  }

  return (
    <div className="divAvailableShip" style={bouncyStyle} onClick={() => {
      setSelectedStarShip(starShipData.key);
    }}>
      {shipImage}<br />
      {starShipData.key}<br />
    </div>
  )
}
