import React, { useState, useEffect }  from 'react';
import Launcher from '../launcher';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import Modal from 'react-modal';
import AvailableShip from '../availableShip';
import WorkerBuilder from '../../workers/worker-builder';
import {
	getEndPoints,
	toTitleCase,
	spaceStationInfo,
	convertUnixTime,
	rarity,
	fancyTimeFormat,
	getMinDiff,
	contractAddress,
	sessionEndpoint
} from '../../helpers';
import FetchAvailableRentalShips from '../../workers/fetchAvailableRentalShips.worker.js';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import Adarea1 from '../adarea1';

export default function Rentals(props) {
  const setGameScreen = props.setGameScreen;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const availableBalance = props.availableBalance;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const toast = props.toast;
  const fleet = props.fleet;
  const getFleet = props.getFleet;
  const selectedStarShip = props.selectedStarShip;
  const setSelectedStarShip = props.setSelectedStarShip;
  const shipData = props.shipData;
  const getShipData = props.getShipData;
  const [availableShips, setAvailableShips] = useState([]);
  const [shipsTotal, setShipsTotal] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);

  let currentDate = new Date().getTime();
  let timeDiff = shipData.end * 1000 - currentDate;
  let statusText = '';
  let rentButton = '';
  let subtitle;

  let modalContent;

  let customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: '75vw',
      height: '80vh',
      borderWidth: '2px',
      borderColor: 'rgba(23, 22, 41, 1)',
      backgroundColor: 'rgba(33, 30, 99, 0.5)',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '3'
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  let backgroundImage = (
    <img src="rentals.jpg" className="desktopBackground" />
  );

  if (selectedStarShip !== 0) {
    if (shipData.planet > 0) {
      if (timeDiff > 0) {
        statusText = `En route to planet ${shipData.planet}`;
      } else {
        statusText = `Ready to mine planet ${shipData.planet}`;
      }
    } else {
      if (timeDiff > 0) {
        statusText = `En route to ${spaceStationInfo()[shipData.spacestation].name}`;
      } else {
        statusText = 'Ready';

        modalContent = (
          <div style={{width: '100%'}}>
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Enter Days and Commission for Ship {shipData.key}</h2>
            <hr />

            <label for="txtDays">Enter number of days between 3 and 30</label><br />
            <input type="number" min="3" max="30" id="txtDays" />
            <hr />
            <label for="txtCommssion">Enter your commission between 5 and 80</label><br />
            <input type="number" min="5" max="80" id="txtCommission" />
            <hr />
            <button className="bigColorfulButton" onClick={async () => {
              let waxUserAccount = '';
              let player = '';
              if (anchorWax) {
                let { session } = identity;
                waxUserAccount = session.auth.actor;
                player = session.auth.actor;
              } else {
                waxUserAccount = wax.userAccount;
                player = wax.userAccount;
              }

              let actions = [
                {
                  account: contractAddress,
                  name: 'offerrental',
                  authorization: [{
                    actor: waxUserAccount,
                    permission: 'active',
                  }],
                  data: {
                    player: player,
                    ship_id: shipData.key,
                    rental_days: document.getElementById('txtDays').value,
                    commission: document.getElementById('txtCommission').value
                  },
                }
              ];

              try {
                let result;
                if (cloudWallet) {
                  result = await wax.api.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  });
                } else {
                  let { session } = identity;
                  result = await session.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  })
                }

                toast.success('Successfully rented this ship!',{
                  position: toast.POSITION.TOP_CENTER
                });
              } catch(ex) {
                toast.error(ex.toString(),{
                  position: toast.POSITION.TOP_CENTER
                });
              }
            }}>Offer Rental</button>
          </div>
        );

        rentButton = (
          <button className="smallColorfulButton" onClick={() => {
            openModal();
          }}>Rent Out Ship</button>
        );
      }
    }
  }

  useEffect(() => {
    let fetchAvailableRentalShipsInstance = new WorkerBuilder(FetchAvailableRentalShips);
    fetchAvailableRentalShipsInstance.onmessage = (message) => {
      if (message) {
        fetchAvailableRentalShipsInstance.postMessage('close');

        if (typeof message.data !== 'undefined') {
          setAvailableShips(message.data);
          setShipsTotal(message.data.length);
        }
      }
    };

    getShipData(selectedStarShip);

    // fetchAvailableRentalShipsInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, contractAddress]);
    fetchAvailableRentalShipsInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, contractAddress]);
  }, [shipsTotal, selectedStarShip]);

  async function rentAShip(ship_id) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'rentaship',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          ship_id: ship_id
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Successfully rented this ship!',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  return (
    <div>
      <div className="divRentalsDesktop">
        <div className="divDesktopBackground">
          {backgroundImage}
        </div>

        <div className="divDesktopContent">
          <div className="divRentalTable">
            <table className="rentalTable" cellSpacing="15">
              <thead>
                <tr>
                  <th>Ship ID</th>
                  <th>Owner ID</th>
                  <th>Renter ID</th>
                  <th>Rental Days</th>
                  <th>Rental Started</th>
                  <th>Rental Ends</th>
                  <th>Owner Commission</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
              {availableShips.map((ship, i) => {
                let rentalButton = '';
                if (ship.rental_end == 0) {
                  rentalButton = (
                    <button className="smallColorfulButton" onClick={() => {
                      rentAShip(ship.key);
                    }}>Rent Ship</button>
                  );
                }

                if (ship.rental_end == 0 || ship.rental_end > (new Date().getTime() / 1000)) {
                  return (
                    <tr>
                      <td>{ship.key}</td>
                      <td>{ship.owner_id}</td>
                      <td>{ship.renter_id}</td>
                      <td style={{textAlign: 'right'}}>{ship.rental_days}</td>
                      <td>{ship.rental_start == 0 ? '' : convertUnixTime(ship.rental_start)}</td>
                      <td>{ship.rental_end == 0 ? '' : convertUnixTime(ship.rental_end)}</td>
                      <td style={{textAlign: 'right'}}>{ship.owner_commission}%</td>
                      <td style={{paddingLeft: '1em', paddingRight: '1em'}}>{rentalButton}</td>
                    </tr>
                  );
                } else {
                  return '';
                }
              })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <div className="divShipSelector">
            {fleet.map((starShipData, j) => {
              return (
                <AvailableShip selectedStarShip={selectedStarShip} starShipData={starShipData} setSelectedStarShip={setSelectedStarShip} />
              );
            })}
          </div>

          <div className="divSelectedStarShipStatus">
            <div style={{position: 'relative', left: '-50%'}}>
              {selectedStarShip.toString() === '0' ? '' : selectedStarShip} {statusText} {rentButton}
            </div>
          </div>

          <img src={controlPanelBackground} alt="" />
          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
              let waxUserAccount = '';
              let player = '';
              if (anchorWax) {
                let { session } = identity;
                waxUserAccount = session.auth.actor;
                player = session.auth.actor;
              } else {
                waxUserAccount = wax.userAccount;
                player = wax.userAccount;
              }

              let actions = [
                {
                  account: contractAddress,
                  name: 'claim',
                  authorization: [{
                    actor: waxUserAccount,
                    permission: 'active',
                  }],
                  data: {
                    player: player
                  },
                }
              ];

              try {
                let result;
                if (cloudWallet) {
                  result = await wax.api.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  });
                } else {
                  let { session } = identity;
                  result = await session.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  })
                }

                toast.success('Kyanite Claimed!',{
                  position: toast.POSITION.TOP_CENTER
                });
              } catch(ex) {
                toast.error(ex,{
                  position: toast.POSITION.TOP_CENTER
                });
              }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Select Parts">
        <div>
          <button className="bigColorfulButton" onClick={closeModal}>Close</button>
        </div>

        <div className="modalContent">
          {modalContent}
        </div>

      </Modal>

      <div className="divMobileRentals">
        <div>
          <h1 className="screenTitle">Rentals</h1>
            {availableShips.map((ship, i) => {
              let rentalButton = '';
              if (ship.rental_end == 0) {
                rentalButton = (
                  <button className="smallColorfulButton" onClick={() => {
                    rentAShip(ship.key);
                  }}>Rent Ship</button>
                );
              }
              if (ship.rental_end == 0 || ship.rental_end > (new Date().getTime() / 1000)) {
                return (
                  <div className="divAvailableRentalShipMobile" key={i}>
                    Ship Id: {ship.key}<br />
                    Ship Owner: {ship.owner_id}<br />
                    Ship Renter: {ship.renter_id}<br />
                    Rental Days: {ship.rental_days}<br />
                    Rental Start: {convertUnixTime(ship.rental_start)}<br />
                    Rental End: {convertUnixTime(ship.rental_end)}<br />
                    Owner Commission: {ship.owner_commission}<br />
                    {rentalButton}
                  </div>
                );
              } else {
                return '';
              }
            })}
          <hr />
          {fleet.map((ship, i) => {
            let currentDate = new Date().getTime();
            let timeDiff = ship.end * 1000 - currentDate;
            let rentButton = '';

            if (ship.planet === 0) {
              if (timeDiff <= 0) {
                rentButton = (
                  <button className="smallColorfulButton" onClick={() => {
                    setSelectedStarShip(ship.key);
                    openModal();
                  }}>Rent Out Ship</button>
                );
              }
            }

            return (
              <div className="divAvailableRentalShipMobile" key={i}>
                Ship Id: {ship.key}<br />
                Rarity: {rarity(ship.rarity)}<br />
                {rentButton}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
