/* eslint-disable */
// fetch.worker.js
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (e) => {
    if (e.data === 'close') {
      self.close();
    } else {
      let url = e.data[0];
      let id = e.data[1];
      let contract = e.data[2];

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(
          {
            json: true,
            code: contract,
            table: 'rewards',
            scope: contract,
            'lower_bound': id,
            'upper_bound': id,
            'index_position': '2',
            'key_type': 'name',
            reverse: false,
            'show_player': false,
            limit: 1000
          }),
        mode: 'cors',
        headers: {
          'Content-Type': 'text/plain',
          'X-Requested-With': 'JSON'
        }
      })
      .then(response => response.json())
      .then(data => {
        postMessage(data);
      });
    }
  };
};
