import React, { useState, useEffect } from 'react';
import {spaceStationInfo, rarity, isProd, contractAddress, ipfsNode} from '../../helpers';
import EquippedPart from '../equippedPart';
import AvailablePart from './availablePart';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import Launcher from '../launcher';
import AvailableShip from '../availableShip';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import Modal from 'react-modal';
//import adarea1
import Adarea1 from '../adarea1';

export default function Hangar(props) {
  const fleet = props.fleet;
  const setGameScreen = props.setGameScreen;
  const toast = props.toast;
  const availableBalance = props.availableBalance;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const getShipData = props.getShipData;
  const starShips = props.starShips;
  const shipData = props.shipData;
  const components = props.components;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const getComponents = props.getComponents;
  const [selectedStarShip, setSelectedStarShip] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);

  let currentDate = new Date().getTime();
  let timeDiff = shipData.end * 1000 - currentDate;
  let statusText = '';
  let shipImage = '';
  let partsButton = '';
  let subtitle;
  let modalContent;

  let customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: '75vw',
      height: '80vh',
      borderWidth: '2px',
      borderColor: 'rgba(23, 22, 41, 1)',
      backgroundColor: 'rgba(33, 30, 99, 0.5)',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '3'
    },
  };



  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getComponents();
    getShipData(selectedStarShip);
  }, [selectedStarShip]);

  if (selectedStarShip !== 0) {
    if (shipData.planet > 0) {
      if (timeDiff > 0) {
        statusText = `En route to planet ${shipData.planet}`;
      } else {
        statusText = `Ready to mine planet ${shipData.planet}`;
      }
    } else {
      if (timeDiff > 0) {
        statusText = `En route to ${spaceStationInfo()[shipData.spacestation].name}`;
      } else {
        statusText = 'Ready';

        partsButton = (
          <button className="smallColorfulButton" onClick={() => {
            openModal();
          }}>Show Parts</button>
        );

        switch (shipData.rarity) {
          case 0:
            shipImage = (
              <img src="shipCommon.png" alt="Common Ship" />
            );
            break;
          case 1:
            shipImage = (
              <img src="shipUncommon.png" alt="Uncommon Ship" />
            );
            break;
          case 2:
            shipImage = (
              <img src="shipRare.png" alt="Rare Ship" />
            );
            break;
          case 3:
            shipImage = (
              <img src="shipEpic.png" alt="Epic Ship" />
            );
            break;
          case 4:
            shipImage = (
              <img src="shipLegendary.png" alt="Legendary Ship" />
            );
            break;
          case 5:
            shipImage = (
              <img src="shipMythic.png" alt="Mythic Ship" />
            );
            break;
          case 6:
            shipImage = (
              <img src="shipFounder.png" alt="Founder Ship" />
            );
            break;
        }
      }
    }
  }

  modalContent = (
    
    
    <div style={{width: '100%'}}>
      <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Select Part</h2>
      <div className="divAvailableParts">
      <h3>Flight Control Chair</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url='0_Flight_Control_Chair_Mythic.gif';
        }

        if (component.data.type === 'Flight Control Chair') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} wax={wax} starShip={selectedStarShip} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Cooling System</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '1_Cooling_System_Mythic.gif';
        }

        if (component.data.type === 'Cooling System') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>High-Power Thruster</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '2_High-Power_Thruster_Mythic.gif';
        }

        if (component.data.type === 'High-Power Thruster') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Secondary Thrusters</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '3_Secondary_Thrusters_Mythic.gif';
        }

        if (component.data.type === 'Secondary Thrusters') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Holo Computer</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '4_Holo-Computer_Mythic.gif';
        }

        if (component.data.type === 'Holo-Computer') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>AI System</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '5_AI_System_Mythic.gif';
        }

        if (component.data.type === 'AI System') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} videoSource={videoSource} url={url} key={i} component={component} />
          );
        }
      })}

      <h3>Life Support</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '6_Life_Support_Mythic.gif';
        }

        if (component.data.type === 'Life Support') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Shield System</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '7_Shield_System_Mythic.gif';
        }

        if (component.data.type === 'Shield System') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Kyanite Storage</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '8_Kyanite_Storage_Mythic.gif';
        }

        if (component.data.type === 'Kyanite Storage') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Kyanite Extractor</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          //videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '9_Kyanite_Extractor_Mythic.gif';
        }

        if (component.data.type === 'Kyanite Extractor') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Engine</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '10_Engine_Mythic.gif';
        }

        if (component.data.type === 'Engine') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}

      <h3>Kyanite Resonator</h3>

      Available:<br />
      {components.map((component, i) => {
        let videoSource = '';
        let url = '';

        if (typeof component.data.img !== 'undefined') {
	        url = `https://${ipfsNode}/ipfs/${component.data.img}`;
        } else {
          // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
          url = '';
        }

        if (component.data.type === 'Kyanite Resonator') {
          return (
            <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
          );
        }
      })}
      </div>
    </div>
  );

  let divShipDescription = '';

  if (selectedStarShip !== 0) {
    divShipDescription = (
      <div className="divShipDescription">
        <div style={{paddingLeft: '70px', paddingTop: '20px'}}>
        <h3 style={{marginBottom: '0.25em'}}>Ship {shipData.key}</h3>
          <table>
            <tbody>
              <tr>
                <td>Rarity</td>
                <td style={{textAlign: 'right', paddingLeft: '15px'}}>{rarity(shipData.rarity)}</td>
              </tr>
              <tr>
                <td>Consumption</td>
                <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(shipData.consumption).toFixed(4)}</td>
              </tr>
              <tr>
                <td>Staking Capacity</td>
                <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(shipData.staking_capacity).toFixed(4)}</td>
              </tr>
              <tr>
                <td>Mining Capacity</td>
                <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(shipData.mining_capacity).toFixed(4)}</td>
              </tr>
              <tr>
                <td>Storage Capacity</td>
                <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(shipData.storage_capacity).toFixed(4)}</td>
              </tr>
              <tr>
                <td>Speed</td>
                <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(shipData.speed).toFixed(4)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  let shipLoadout = ''

  if (selectedStarShip !== 0) {
    shipLoadout = (
      <div className="divShipLoadout">
        <div style={{paddingLeft: '70px', paddingTop: '35px'}}>
          <h3 style={{marginBottom: '0.5em' }}>Ship {shipData.key} Loadout</h3>
          <table>
            <tbody>
            {shipData.parts.map((part, i) => { 
                return (
                  <EquippedPart
                    order={i}
                    key={i}
                    shipId={shipData.key}
                    partId={part}
                    identity={identity}
                    cloudWallet={cloudWallet}
                    anchorWax={anchorWax}
                    toast={toast}
                    statusText={statusText}
                    getComponents={getComponents}
                    getShipData={getShipData}
                    selectedStarShip={selectedStarShip}
                    starShip={selectedStarShip}
                    wax={wax}
                  />
                );
            })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="divHangarDesktop">
        <div className="divDesktopBackground">
          <img src="hangar.jpg" className="desktopBackground" />
        </div>
        <div className="divDesktopContent">
            {divShipDescription}
            {shipLoadout}
          <div className="divSelectedHangarShip">
	          <div className="ship-flex-container">
		          {shipImage}
	          </div>

            {/*<div className="divEquippedParts"></div>*/}
          </div>
        </div>
        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <div className="divShipSelector">
            {fleet.map((starShipData, j) => {
              return (
                <AvailableShip selectedStarShip={selectedStarShip} starShipData={starShipData} setSelectedStarShip={setSelectedStarShip} />
              );
            })}
          </div>
          <div className="divSelectedStarShipStatus">
            <div style={{position: 'relative', left: '-50%'}}>
              {selectedStarShip.toString() === '0' ? '' : selectedStarShip} {statusText} {partsButton}
            </div>
          </div>
          <img src={controlPanelBackground} alt="" />
          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
            let waxUserAccount = '';
            let player = '';
            if (anchorWax) {
              let { session } = identity;
              waxUserAccount = session.auth.actor;
              player = session.auth.actor;
            } else {
              waxUserAccount = wax.userAccount;
              player = wax.userAccount;
            }

            let actions = [
              {
                account: contractAddress,
                name: 'claim',
                authorization: [{
                  actor: waxUserAccount,
                  permission: 'active',
                }],
                data: {
                  player: player
                },
              }
            ];

            try {
              let result;
              if (cloudWallet) {
                result = await wax.api.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                });
              } else {
                let { session } = identity;
                result = await session.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                })
              }

              toast.success('Kyanite Claimed!',{
                position: toast.POSITION.TOP_CENTER
              });
            } catch(ex) {
              toast.error(ex,{
                position: toast.POSITION.TOP_CENTER
              });
            }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Select Parts">
          <div>
            <button className="bigColorfulButton" onClick={closeModal}>Close</button>
          </div>

          <div className="modalContent">
            {modalContent}
          </div>

        </Modal>
      </div>

      <div className="divHangarMobile">
        <h1 className="screenTitle">Hangar</h1>
        <div className="divMobileShipSelector">
          <select onChange={event => {
            if (event.target.value === '') {
              setSelectedStarShip();
            } else {
              setSelectedStarShip(event.target.value);
            }

            getShipData(event.target.value);
          }}>
          <option value="">Select Star Ship</option>
            {fleet.map((starShip, i) => {
              return (
                <option key={i} value={starShip.key}>Star Ship {starShip.key}</option>
              );
            })}
          </select>
        </div>

        <div className="divMobilePartSelectors">
          <div>
            <hr />
            Ship Status: {statusText}
            <hr />
            Equipped Parts:<br />

            {shipData.parts.map((part, i) => {

              return (
                <EquippedPart
                    order={i}
                    key={i}
                    shipId={shipData.key}
                    partId={part}
                    identity={identity}
                    cloudWallet={cloudWallet}
                    anchorWax={anchorWax}
                    toast={toast}
                    statusText={statusText}
                    getComponents={getComponents}
                    getShipData={getShipData}
                    selectedStarShip={selectedStarShip}
                    starShip={selectedStarShip}
                    wax={wax}
                  />
              )
              
            })}

            <hr />
            Ship Stats:<br />
            Consumption: {shipData.consumption}<br />
            Mining Capacity: {shipData.mining_capacity}<br />
            Rarity: {shipData.rarity}<br />
            Speed: {shipData.speed}<br />
            Staking Capacity: {shipData.staking_capacity}<br />
            Storage Capacity: {shipData.storage_capacity}
            <hr />

            <h3>Flight Control Chair</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url='0_Flight_Control_Chair_Mythic.gif';
              }

              if (component.data.type === 'Flight Control Chair') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax}  toast={toast} statusText={statusText} getComponents={getComponents} wax={wax} starShip={selectedStarShip} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Cooling System</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '1_Cooling_System_Mythic.gif';
              }

              if (component.data.type === 'Cooling System') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>High-Power Thruster</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '2_High-Power_Thruster_Mythic.gif';
              }

              if (component.data.type === 'High-Power Thruster') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Secondary Thrusters</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '3_Secondary_Thrusters_Mythic.gif';
              }

              if (component.data.type === 'Secondary Thrusters') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Holo Computer</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '4_Holo-Computer_Mythic.gif';
              }

              if (component.data.type === 'Holo-Computer') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>AI System</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '5_AI_System_Mythic.gif';
              }

              if (component.data.type === 'AI System') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} videoSource={videoSource} url={url} key={i} component={component} />
                );
              }
            })}

            <h3>Life Support</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '6_Life_Support_Mythic.gif';
              }

              if (component.data.type === 'Life Support') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Shield System</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '7_Shield_System_Mythic.gif';
              }

              if (component.data.type === 'Shield System') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Kyanite Storage</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '8_Kyanite_Storage_Mythic.gif';
              }

              if (component.data.type === 'Kyanite Storage') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Kyanite Extractor</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                //videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '9_Kyanite_Extractor_Mythic.gif';
              }

              if (component.data.type === 'Kyanite Extractor') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Engine</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '10_Engine_Mythic.gif';
              }

              if (component.data.type === 'Engine') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}

            <h3>Kyanite Resonator</h3>

            Available:<br />
            {components.map((component, i) => {
              let videoSource = '';
              let url = '';

              if (typeof component.data.img !== 'undefined') {
	              url = `https://${ipfsNode}/ipfs/${component.data.img}`;
              } else {
                // videoSource = `https://ipfs.infura.io/ipfs/${component.data.video}`;
                url = '11_Resonator_Mythic.png';
              }

              if (component.data.type === 'Kyanite Resonator') {
                return (
                  <AvailablePart getShipData={getShipData} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} toast={toast} statusText={statusText} getComponents={getComponents} starShip={selectedStarShip} wax={wax} url={url} videoSource={videoSource} key={i} component={component} />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
