import React from 'react';

export default function InfoPanel(props) {
  const starShipPrice = props.starShipPrice;
  const userBalance = props.userBalance;

  return (
    <div className="desktopInfoPanel">
      <p style={{marginBottom: '0.12em', marginRight: '0.25em', marginLeft: '0.25em', textAlign: 'center'}}>$STARSHIP</p>
      <p style={{marginTop: '0.12em', marginBottom: '0.12em', marginRight: '0.25em', marginLeft: '0.25em'}}>Price: ${parseFloat(starShipPrice).toFixed(4)}</p>
      <p style={{marginTop: '0.12em', marginBottom: '0.12em', marginRight: '0.25em', marginLeft: '0.25em'}}>Balance: {userBalance}</p>
      <p style={{marginTop: '0.12em', marginBottom: '0.12em', marginRight: '0.25em', marginLeft: '0.25em'}}>Value: ${(parseFloat(starShipPrice) * parseFloat(userBalance)).toFixed(2)}</p>
    </div>
  );
}
