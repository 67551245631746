import React from 'react';

export default function Updates(props) {
  return (
    <div className="desktopUpdates">

     <p style={{marginTop: '1.25em', marginBottom: '0.25em', paddingRight: '0.5em', marginRight: '0.5em', marginLeft: '0.25em'}}>
       Starship Collectors Edition puzzles are now live!
     </p>

     <p style={{marginTop: '0.25em', marginBottom: '0.25em', paddingRight: '0.5em', marginRight: '0.5em', marginLeft: '0.25em'}}>
        Visit the <a href="https://www.deploystarship.com/shop" target="_new">Starship Shop</a>.
     </p>

    </div>
  );
}
