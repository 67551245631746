import React, { useState, useEffect }  from 'react';
import Launcher from '../launcher';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import WorkerBuilder from '../../workers/worker-builder';
import FetchTopKyaniteHolders from '../../workers/fetchTopKyaniteHolders.worker.js';
import {getEndPoints, contractAddress} from '../../helpers';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import Adarea1 from '../adarea1';
import FetchShipBalance from '../../workers/fetchShipBalance.worker.js';
import {
	sessionEndpoint
} from '../../helpers';

export default function Leaderboards(props) {
  const setGameScreen = props.setGameScreen;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const availableBalance = props.availableBalance;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const toast = props.toast;
  const [topKyaniteHolders, setTopKyaniteHolders] = useState([]);
  const [fetchingShipBalance, setFetchingShipBalance] = useState(false);

  const [allMintedKyanite, setAllMintedKyanite] = useState(0);
  const [allBurnedKyanite, setAllBurnedKyanite] = useState(0);

  let backgroundImage = (
    <img src="leaderboards.jpg" className="desktopBackground" />
  );

  useEffect(() => {
    getShipBalance();
  }, []);


  function getShipBalance() {
    if (!fetchingShipBalance) {
      let fetchShipBalanceInstance = new WorkerBuilder(FetchShipBalance);
      fetchShipBalanceInstance.onmessage = (message) => {
        if (message) {
          fetchShipBalanceInstance.postMessage('close');

          if (typeof message.data !== 'undefined') {
            if (typeof message.data.rows !== 'undefined') {
              for (let i=0; i<message.data.rows.length; i++) {
                switch(message.data.rows[i].par_name) {
                  case 'mintedkyaall':
                    setAllMintedKyanite(message.data.rows[i].value);
                    break;
                  case 'burnedkyaall':
                    setAllBurnedKyanite(message.data.rows[i].value);
                    break;
                }
              }
            }
          }

          setFetchingShipBalance(false);
        }
      };

      // fetchShipBalanceInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, contractAddress]);
      fetchShipBalanceInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, contractAddress]);
      setFetchingShipBalance(true);
    }
  }


  useEffect(() => {
    let fetchTopKyaniteHoldersInstance = new WorkerBuilder(FetchTopKyaniteHolders);
    fetchTopKyaniteHoldersInstance.onmessage = (message) => {
      if (message) {
        fetchTopKyaniteHoldersInstance.postMessage('close');

        if (typeof message.data !== 'undefined') {
          console.debug(message.data);
          setTopKyaniteHolders(message.data);
        }
      }
    };

    fetchTopKyaniteHoldersInstance.postMessage([`https://www.deploystarship.io/player/players.json`]);
  }, []);

  return (
    <div>
      <div className="divLeaderboardsDesktop">
        <div className="divDesktopBackground">
          {backgroundImage}
        </div>
        <div className="divDesktopContent">



        <h1 style={{color: 'white', textAlign: 'center'}}>Kyanite Stats</h1>
          <div className="divTopKyaniteHolderTable">
            <table className="kyaniteHolderTable" cellSpacing="15">
              <thead>
                <tr>
                  <th>Minted Kyanite</th>
                  <h2>VS</h2>
                  <th>Burned Kyanite</th>
                </tr>
              </thead>
              <tbody>


                    <tr>
                      <td>{Math.floor(allMintedKyanite).toLocaleString("en-US")}</td>
                      <div></div>
                      <td style={{textAlign: 'right'}}>{Math.floor(allBurnedKyanite).toLocaleString("en-US")}</td>
                    </tr>


              </tbody>
            </table>
            <h2>TOTAL</h2>
            <h4 style={{color: 'white', textAlign: 'center', marginTop:"5px"}}>{Math.floor(allMintedKyanite - allBurnedKyanite).toLocaleString("en-US")}</h4>
          </div>
          <p style={{color: 'white', textAlign: 'center', marginBottom:"0px"}}>This data has been recorded since February 16 2023</p>



          <h1 style={{color: 'white', textAlign: 'center'}}>Top Ten Kyanite Holders</h1>
          <div className="divTopKyaniteHolderTable">
            <table className="kyaniteHolderTable" cellSpacing="15">
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Kyanite</th>
                </tr>
              </thead>
              <tbody>
                {topKyaniteHolders.map((holder, i) => {
                  return (
                    <tr key={i}>
                      <td>{holder.player}</td>
                      <td style={{textAlign: 'right'}}>{holder.kyanite}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <img src={controlPanelBackground} alt="" style={{}} />
          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
              let waxUserAccount = '';
              let player = '';
              if (anchorWax) {
                let { session } = identity;
                waxUserAccount = session.auth.actor;
                player = session.auth.actor;
              } else {
                waxUserAccount = wax.userAccount;
                player = wax.userAccount;
              }

              let actions = [
                {
                  account: contractAddress,
                  name: 'claim',
                  authorization: [{
                    actor: waxUserAccount,
                    permission: 'active',
                  }],
                  data: {
                    player: player
                  },
                }
              ];

              try {
                let result;
                if (cloudWallet) {
                  result = await wax.api.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  });
                } else {
                  let { session } = identity;
                  result = await session.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  })
                }

                toast.success('Kyanite Claimed!',{
                  position: toast.POSITION.TOP_CENTER
                });
              } catch(ex) {
                toast.error(ex,{
                  position: toast.POSITION.TOP_CENTER
                });
              }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>
      </div>

      <div className="divMobileLeaderboards">
        
          <div style={{marginTop:"35px"}}>
          <h1 className="screenTitle">Kyanite Stats</h1>
          <div style={{color: 'white'}}>
            
            <table cellSpacing="15" style={{marginLeft: 'auto', marginRight: 'auto', marginTop:"35px"}}>
              <thead>
                <tr>
                  <th>Minted Kyanite</th>
                  <th>Burned Kyanite</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                      <td>{Math.floor(allMintedKyanite).toLocaleString("en-US")}</td>
          
                      <td style={{textAlign: 'right'}}>{Math.floor(allBurnedKyanite).toLocaleString("en-US")}</td>
                    </tr>
              </tbody>
              
            </table>
            <h2 style={{textAlign:"center"}}>TOTAL</h2>
            <h4 style={{color: 'white', textAlign: 'center', marginTop:"5px"}}>{Math.floor(allMintedKyanite - allBurnedKyanite).toLocaleString("en-US")}</h4>
          </div>
        </div>
          
        <div>
          <h1 className="screenTitle">Leaderboards</h1>
          <h2 style={{color: 'white', textAlign: 'center', marginTop: '2em'}}>Top Ten Kyanite Holders</h2>
          <div style={{color: 'white'}}>
            <table cellSpacing="15" style={{marginLeft: 'auto', marginRight: 'auto'}}>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Kyanite</th>
                </tr>
              </thead>
              <tbody>
                {topKyaniteHolders.map((holder, i) => {
                  return (
                    <tr key={i}>
                      <td>{holder.player}</td>
                      <td style={{textAlign: 'right'}}>{holder.kyanite}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
