import React, { useState, useEffect } from 'react';
import { enviromentVariables } from '../../helpers';

export default function EquippedPart(props) {
  const [partName, setPartName] = useState("");
  const [partRarity, setPartRarity] = useState("");
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const getComponents = props.getComponents;
  const toast = props.toast;
  const getShipData = props.getShipData;
  const selectedStarShip = props.selectedStarShip;
  const componentNames = [
    "FlightControlChair",
    "CoolingSystem",
    "HighPowerThruster",
    "SecondaryThrusters",
    "HoloComputer",
    "AISystem",
    "LifeSupport",
    "ShieldSystem",
    "KyaniteStorage",
    "KyaniteExtractor",
    "Engine",
    "KyaniteResonator",
  ];

  async function unattachPart(assetId, starShip) {
    let waxUserAccount = "";
    let player = "";

    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: enviromentVariables().contract,
        name: "unattachpart",
        authorization: [
          {
            actor: waxUserAccount,
            permission: "active",
          },
        ],
        data: {
          player: player,
          starship: props.shipId,
          part: props.partId,
        },
      },
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else {
        let { session } = identity;
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }

      toast.success(partName + " unequipped!",{
        position: toast.POSITION.TOP_CENTER
      });

      // Wait five seconds and reload the parts
      setTimeout(() => {
        getComponents();
        getShipData(selectedStarShip);
      }, 5000);
    } catch (ex) {
      toast.error(ex,{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  useEffect(() => {
    if (parseInt(props.partId, 10) !== 0) {
      fetch(
        `https://${enviromentVariables().atomichub}/atomicassets/v1/assets/${
          props.partId
        }`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "text/plain; charset=utf-8",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (typeof data.data !== "undefined") {
            setPartName(data.data.data.name);
            setPartRarity(data.data.data.rarity);
          }
        });
    }
  });
  if (props.partId !== 0) {
    return (
      <tr style={{ fontSize:"15px"}}>
        <td>{partName}</td>
        <td style={{ textAlign: "right", paddingLeft: "15px" }}>
          <button
            className="smallColorfulButton"
            style={{ fontSize: "10px"}}
            onClick={() =>
              unattachPart(props.partId, props.starShip, props.getComponents)
            }
          >
            Unequip
          </button>
        </td>
        <td style={{ textAlign: "right", paddingLeft: "15px" }}>
          {partRarity}
        </td>
      </tr>
    );
  } else {
    return (
      <tr style={{ fontSize:"15px", opacity:"50%" }}>
        <td>{componentNames[props.order]}</td>
        <td style={{ textAlign: "right", paddingLeft: "15px" }}>
        </td>
        <td style={{ textAlign: "right", paddingLeft: "15px" }}>Empty Slot</td>
      </tr>
    );
  }
}
