/* eslint-disable */
// fetchRentedShips.worker.js
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable-next-line no-restricted-globals */
  async function getRentalShipData(url, id, contract) {
    return await fetch(url, {
      method: 'POST',
      body: JSON.stringify(
        {
          json: true,
          code: contract,
          table: 'starshipdata',
          scope: contract,
          'lower_bound': id,
          'upper_bound': id,
          reverse: false,
          'show_payer': false
        }),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'JSON'
      }
    });
  }

  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (e) => {
    if (e.data === 'close') {
      self.close();
    } else {
      let url = e.data[0];
      let id = e.data[1];
      let contract = e.data[2];

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(
          {
            json: true,
            code: contract,
            table: 'rentalships',
            scope: contract,
            'lower_bound': id,
            'upper_bound': id,
            'index_position': '2',
            'key_type': 'name',
            reverse: false,
            'show_payer': false,
            limit: 1000
          }),
        mode: 'cors',
        headers: {
          'Content-Type': 'text/plain',
          'X-Requested-With': 'JSON'
        }
      })
      .then(response => response.json())
      /* eslint-disable-next-line no-restricted-globals */
      .then((data) => {

        (async (data) => {
          console.debug('data', data);

          let rentalShips = [];
          let responseData = data;

          if (typeof responseData.rows != 'undefined') {
            let shipResponse;

            for (let i=0; i<responseData.rows.length; i++) {
              shipResponse = await getRentalShipData(url, responseData.rows[i].key, contract);
              let shipData = await shipResponse.json();

              if (data.rows[i].rental_end > (new Date().getTime() / 1000)) {
                rentalShips.push(shipData.rows[0]);
              }
            }

            postMessage(rentalShips);
          }
        })(data);
      });
    }
  };
};
