/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import ShipData from './shipData';
import FetchPlanetsForMission from '../../workers/fetchPlanetsForMission.worker.js';
import {
	toTitleCase,
	spaceStationInfo,
	convertUnixTime,
	fancyTimeFormat,
	getMinDiff,
	rarity,
	getEndPoints,
	contractAddress,
	sessionEndpoint,
  ipfsNode
} from '../../helpers';
import FetchShipBalance from '../../workers/fetchShipBalance.worker.js';
import FetchDestinationPlanet from '../../workers/fetchDestinationPlanet.worker.js';
import WorkerBuilder from '../../workers/worker-builder';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import Launcher from '../launcher';
import AvailableShip from '../availableShip';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import Modal from 'react-modal';
import Select from 'react-select';
import crystalPlanet from '../../images/crystal.png';
import gasPlanet from '../../images/gas.png';
import desertPlanet from '../../images/desert.png';
import icePlanet from '../../images/ice.png';
import greenlandPlanet from '../../images/greenland.png';
import swampPlanet from '../../images/swamp.png';
import oceanPlanet from '../../images/ocean.png';
import magmaPlanet from '../../images/magma.png';
import paradisePlanet from '../../images/paradise.png';
import metallicPlanet from '../../images/metallic.png';
import Adarea1 from '../adarea1';

export default function Missions(props) {
  const fleet = props.fleet;
  const rentedShips = props.rentedShips;
  const setGameScreen = props.setGameScreen;
  const toast = props.toast;
  const availableBalance = props.availableBalance;
  const getFleet = props.getFleet;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const selectedStarShip = props.selectedStarShip;
  const setSelectedStarShip = props.setSelectedStarShip;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const destinationPlanet = props.destinationPlanet;
  const planetPoolConfig = props.planetPoolConfig;
  const planetUpgConfig = props.planetUpgConfig;
  const userAssets = props.userAssets;
  const getComponents = props.getComponents;
  const [fetchingShipBalance, setFetchingShipBalance] = useState(false);
  const [selectedSpaceStation, setSelectedSpaceStation] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContentState, setModalContentState] = useState(0);
  const [claimplanetc, setClaimPlanetc] = useState(0);
  const [planetData, setPlanetData] = useState([]);
  const [selectedQuadrant, setSelectedQuadrant] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedSystem, setSelectedSystem] = useState('');
  const [planets, setPlanets] = useState([]);
  const [sectorsize, setSectorsize] = useState(0);
  const [kyaniteUpgradeCostMultiplier, setKyaniteUpgradeCostMultiplier ] = useState(0);
  const [selectedPlanet, setSelectedPlanet] = useState(0);
  const [claimperiod, setClaimPeriod] = useState(0);
  const [interdistanc, setInterdistanc] = useState(0);
  const [planetcommis, setPlanetcommis] = useState(0);
  const [planetreward, setPlanetreward] = useState(0);
  const [spacecommis, setSpacecommis] = useState(0);
  const [rewardMod, setRewardMod] = useState(1);
  const [fetchingDestinationPlanetData, setFetchingDestinationPlanetData] = useState(false);
  const [fetchingPlanetData, setFetchingPlanetData] = useState(false);
  const [userAssetsFilter, setUserAssetsFilter] = useState(true);

  let shipData;

  let upgSubArea;
  let planetPoolConf;



  // check if a player owned ship is selected
  {fleet.map((ship, i) => {
    if (ship.key === selectedStarShip) {
        shipData = ship;
    }
  })}

  // check if a rented ship is selected
  {rentedShips.map((ship, i) => {
    if (ship.key === selectedStarShip) {
        shipData = ship;
    }
  })}

 let destinationImage = '';
  let currentDate = new Date().getTime();
  let timeDiff;

  if (selectedStarShip !== 0) {
    timeDiff = shipData.end * 1000 - currentDate;
  }

  let statusText = '';
  let shipImage = '';
  let partsButton = '';
  let subtitle;
  let modalContent;
  let ShowHideButton = "";
  let container;
  let backgroundImage = (
    <img src="missions.jpg" className="desktopBackground" />
  );
  let btnSendToSpaceStation = '';
  let btnSendOnMission = '';
  let btnMinePlanet = '';
  let btnUpgPlanet = '';
  let btnClaimPlanet = '';
  let timeLeft;
  let destinationInfo = '';

  if (selectedStarShip > 0) {
    timeLeft = getMinDiff(currentDate, shipData.end*1000);
  }

  let timeLeftText = '';

  if (selectedStarShip !== 0) {
    timeLeftText = fancyTimeFormat(timeLeft) + ' Remaining';
  }

  let customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: '75vw',
      height: '80vh',
      borderWidth: '2px',
      borderColor: 'rgba(23, 22, 41, 1)',
      backgroundColor: 'rgba(33, 30, 99, 0.5)',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
  };

  let filterButtonStyle = {
    //align the button right
    float: 'right',
    backgroundColor: '#497d83',
  }

  const customOptionStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'red' : 'black',
      padding: 20,
    })
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function filterUserAssets(assets) {
    if (userAssetsFilter) {
      console.log("ASSETS: ", assets)
      //multiple template_ids should be cleaned that to stay only one with the same template_id
      let filteredAssets = [];
      let templateIds = [];
      assets.map((asset, i) => {
        if (!templateIds.includes(asset.template.template_id)) {
          templateIds.push(asset.template.template_id);
          filteredAssets.push(asset);
        }
      }
      );
      console.log("FILTERED ASSETS: ", filteredAssets)
      return filteredAssets;
    } else {
      return assets;
    }

  }

  function checkMaxedOutPlanet(planetType, planetCurrentMaxPool) {
    //loop trough planetpoolconfig and find the item with the same planet type of planetType
    let destinationPlanetConfig = planetPoolConfig.find(planet => planet.type === planetType);
    //if destinationPlanetConfig's max_pool is equal to planetCurrentMaxPool, then return false
    if (destinationPlanetConfig.max_pool === planetCurrentMaxPool) {
      return false;
    }
    return true;

  }

  function solveEnhanceAmount(current_max_pool) {


    //find the planetconf object with the same type as destinationPlanet.type
    let planetconf = planetPoolConfig.find(planet => planet.type === destinationPlanet.type);

    if (((parseFloat(current_max_pool) * 0.1) + parseFloat(current_max_pool)) > planetconf.max_pool) {
      return Math.floor(planetconf.max_pool - current_max_pool);
    }
    //else return %10 of current_max_pool
    return Math.floor(current_max_pool * 0.1) ;
  }


  function changeFilterState() {
    setUserAssetsFilter(!userAssetsFilter);
    //change the buttons color to show the state
    if (userAssetsFilter) {
      document.getElementById("filterButton").style.backgroundColor = "#31585d";
    }
    else {
      document.getElementById("filterButton").style.backgroundColor = "#497d83";
    }

  }
  

  //! UPGRADE PLANET AREA

  

  async function upgradePlanet(asset_id) {
    let waxUserAccount = '';
    let player = '';

    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
          account: 'atomicassets',
          name: 'transfer',
          authorization: [{
              actor: waxUserAccount,
              permission: 'active',
            }],
          data: {
              from: waxUserAccount,
              to: contractAddress,
              asset_ids: [
                asset_id,
              ],
              memo: "Upgrading Ship"
          },
      },{
        account: contractAddress,
        name: 'upgradeplnt',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: selectedStarShip,
          part: asset_id,
          planetid: destinationPlanet.id
        },
      }
    ];



    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Planet Enhanced!', {
        position: toast.POSITION.TOP_CENTER
      })

      // Wait five seconds and reload the parts
      setTimeout(() => {
        getComponents();
        getFleet();
        
        //getShipData(selectedStarShip);
      }, 6000);
    } catch(ex) {
      toast.error(ex,{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function upgradePlanetwithKya() {
    let waxUserAccount = '';
    let player = '';

    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [{
        account: contractAddress,
        name: 'upgplntkya',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: selectedStarShip,
          planetid: destinationPlanet.id
        },
      }
    ];



    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Planet Enhanced!',{
        position: toast.POSITION.TOP_CENTER
      })

      // Wait five seconds and reload the parts
      setTimeout(() => {
        getComponents();
        getFleet();

        
        //getShipData(selectedStarShip);
      }, 6000);
    } catch(ex) {
      toast.error(ex,{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }


    

  //! END UPGRADE PLANET AREA






  async function sendToSpaceStation(starShip) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'move2station',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip,
          spacestation: selectedSpaceStation
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('En route to space station!',{
        position: toast.POSITION.TOP_CENTER
      });

      // Wait five seconds and update the fleet
      setTimeout(() => {
        getFleet();
      }, 5000);
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function launchMission(starShip) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'move2planet',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip,
          planet: selectedPlanet
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Launched on Mission!',{
        position: toast.POSITION.TOP_CENTER
      });

      // Wait five seconds and reload the fleet
      setTimeout(() => {
        getFleet();
      }, 5000);
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function getPlanets() {
    if (selectedSpaceStation !== '' &&
        selectedQuadrant !== '' &&
        selectedSector !== '' &&
        selectedSystem !== ''
    ) {
      let spacestation = selectedSpaceStation * 16384;
      let quadrant = selectedQuadrant * 4096 ;
      let sector = selectedSector * 128;
      let system = selectedSystem * 4;
      let planetIds = [];
      planetIds[0] = spacestation + quadrant + sector + system;
      planetIds[1] = planetIds[0] + 1;
      planetIds[2] = planetIds[0] + 2;
      planetIds[3] = planetIds[0] + 3;
      setPlanets(planetIds);
      getMissionPlanets(planetIds[0]);
    } else {
      setPlanets([]);
    }
  }

  function getMissionPlanets(id) {
    let fetchPlanetDataInstance = new WorkerBuilder(FetchPlanetsForMission);
    fetchPlanetDataInstance.onmessage = (message) => {
      if (message) {
        fetchPlanetDataInstance.postMessage('close');

        if (typeof message.data !== 'undefined') {
          if (typeof message.data.rows !== 'undefined') {
            let pd = [];

            for (let i=0; i<message.data.rows.length; i++) {
              pd[i] = message.data.rows[i];
              pd[i].claimPrice = parseFloat(claimplanetc / 100).toFixed(2) * parseFloat(pd[i].max_pool).toFixed(2);
            }

            setPlanetData(pd);
          }
        }

      }
    };

    // fetchPlanetDataInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, id, contractAddress]);
    fetchPlanetDataInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, id, contractAddress]);
  }

  async function minePlanet(wax, starship) {
    let waxUserAccount = '';
    let player = '';

    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'collect',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: selectedStarShip
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Planet mined! Returning to space station.',{
        position: toast.POSITION.TOP_CENTER
      });

      // Wait five seconds and update the fleet
      setTimeout(() => {
        getFleet();
      }, 5000);
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function claimPlanet(starShip, planetid) {
    let waxUserAccount = '';
    let player = '';

    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'claimplanet',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip,
          planetid: planetid
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Planet claimed!',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  function getShipBalance() {
    if (!fetchingShipBalance) {
      let fetchShipBalanceInstance = new WorkerBuilder(FetchShipBalance);
      fetchShipBalanceInstance.onmessage = (message) => {
        if (message) {
          fetchShipBalanceInstance.postMessage('close');

          if (typeof message.data !== 'undefined') {
            if (typeof message.data.rows !== 'undefined') {
              for (let i=0; i<message.data.rows.length; i++) {
                switch(message.data.rows[i].par_name) {
                  case 'claimperiod':
                    setClaimPeriod(message.data.rows[i].value);
                    break;
                  case 'interdistanc':
                    setInterdistanc(message.data.rows[i].value);
                    break;
                  case 'claimplanetc':
                    setClaimPlanetc(message.data.rows[i].value);
                    break;
                  case 'planetcommis':
                    setPlanetcommis(message.data.rows[i].value);
                    break;
                  case 'planetreward':
                    setPlanetreward(message.data.rows[i].value);
                    break;
                  case 'sectorsize':
                    setSectorsize(message.data.rows[i].value);
                    break;
                  case 'spacescommis':
                    setSpacecommis(message.data.rows[i].value);
                    break;
                  case 'rewardmod' :
                    setRewardMod(message.data.rows[i].value);
                    break;
                  case 'upgkyamult' :
                    setKyaniteUpgradeCostMultiplier(message.data.rows[i].value);
                }
              }
            }
          }

          setFetchingShipBalance(false);
        }
      };

      // fetchShipBalanceInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, contractAddress]);
      fetchShipBalanceInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, contractAddress]);
      setFetchingShipBalance(true);
    }
  }

  useEffect(() => {
    // getShipData(selectedStarShip);
    getShipBalance();
    getPlanets();
  }, [selectedStarShip, selectedQuadrant, selectedSector, selectedSystem]);

  

  useEffect(() => {
    getComponents();
    getShipBalance();
  }, []);


  let today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  tomorrow.setHours(0,0,0,0);

  if (selectedStarShip !== 0) {
    if (shipData.planet > 0) {
      if (timeDiff > 0) {
        if (destinationPlanet !== null) {
          destinationInfo = (
            <div className="divDestinationInfo">
              <div style={{paddingLeft: '70px', paddingTop: '20px'}}>
                <h3 style={{marginBottom: '0.025em'}}>Destination Planet: {destinationPlanet.id}</h3>
                <h3 style={{marginTop: '0.025em', marginBottom: '0.025em'}}>Ship {selectedStarShip}</h3>
                <h3 style={{marginTop: '0.025em', marginBottom: '0.025em'}}>{timeLeftText}</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>Type</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{toTitleCase(destinationPlanet.type)}</td>
                    </tr>
                    <tr>
                      <td>Owner</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{destinationPlanet.owner === contractAddress ? 'None' : destinationPlanet.owner}</td>
                    </tr>
                    <tr>
                      <td>Claim Price</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{destinationPlanet.owner === contractAddress ? (parseFloat(destinationPlanet.max_pool) * (claimplanetc / 100)).toFixed(2) : 'Not Available'}</td>
                    </tr>
                    <tr>
                      <td>Pool</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{destinationPlanet.next_reset > today / 1000 ? parseFloat(destinationPlanet.pool).toFixed(0) : (destinationPlanet.owner === contractAddress ? Math.floor(parseFloat(destinationPlanet.max_pool).toFixed(0) * 0.2) : parseFloat(destinationPlanet.max_pool).toFixed(0))} / {parseFloat(destinationPlanet.max_pool).toFixed(0)}</td>
                    </tr>
                    <tr>
                      <td>Resets</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{(destinationPlanet.next_reset > new Date() / 1000) ? convertUnixTime(destinationPlanet.next_reset) : convertUnixTime(tomorrow / 1000)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        }

        backgroundImage = (
          <img src='shipTravelling.gif' className="desktopBackground" />
        );
      } else {
        if (destinationPlanet !== null) {
          destinationInfo = (
            <div className="divDestinationInfo">
              <div style={{paddingLeft: '70px', paddingTop: '20px'}}>
                <h3 style={{marginBottom: '0.025em'}}>Destination Planet: {destinationPlanet.id}</h3>
                <h3 style={{marginTop: '0.025em', marginBottom: '0.025em'}}>Ship {selectedStarShip}</h3>
                <h3 style={{marginTop: '0.025em', marginBottom: '0.025em'}}>In Orbit</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>Type</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{toTitleCase(destinationPlanet.type)}</td>
                    </tr>
                    <tr>
                      <td>Owner</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{destinationPlanet.owner === contractAddress ? 'None' : destinationPlanet.owner}</td>
                    </tr>
                    <tr>
                      <td>Claim Price</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{destinationPlanet.owner === contractAddress ? (parseFloat(destinationPlanet.max_pool) * (claimplanetc / 100)).toFixed(2) : 'Not Available'}</td>
                    </tr>
                    <tr>
                      <td>Pool</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{destinationPlanet.next_reset > today / 1000 ? parseFloat(destinationPlanet.pool).toFixed(0) : (destinationPlanet.owner === contractAddress ? Math.floor(parseFloat(destinationPlanet.max_pool).toFixed(0) * 0.2) : parseFloat(destinationPlanet.max_pool).toFixed(0))} / {parseFloat(destinationPlanet.max_pool).toFixed(0)}</td>
                    </tr>
                    <tr>
                      <td>Resets</td>
                      <td style={{textAlign: 'right', paddingLeft: '15px'}}>{(destinationPlanet.next_reset > new Date() / 1000) ? convertUnixTime(destinationPlanet.next_reset) : convertUnixTime(tomorrow / 1000)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        }
        btnMinePlanet = (
          <div style={{display: 'inline-block'}}>
            <button className="smallColorfulButton" onClick={() => {
              minePlanet(wax, selectedStarShip);
            }}>Mine Planet</button>
          </div>
        );


        if (destinationPlanet !== null) {
          let account_name;
          if (anchorWax) {
            let { session } = identity;
            //get the account name as a string
            account_name = session.auth.actor.toString();
          } else {
            account_name = wax.userAccount;
          }
          if (destinationPlanet.owner === account_name && checkMaxedOutPlanet(destinationPlanet.type, destinationPlanet.max_pool)) {
            btnUpgPlanet = (
              <div style={{display: 'inline-block'}}>
                <button className="smallColorfulButton" onClick={() => {
                  //upgradePlanet();
                  setModalContentState(2);
                  openModal();
                }}>Enhance</button>
              </div>
            );
          }
        }



     
      if (modalContentState === 2) { 
        ShowHideButton = ( 
          <button id='filterButton' className="bigColorfulButton" style={filterButtonStyle}  onClick={changeFilterState}>Show/Hide Duplicates</button>
        ) }
        

        
        if (destinationPlanet !== null) {
          if (destinationPlanet.owner === contractAddress) {
            btnClaimPlanet = (
              <div style={{display: 'inline-block'}}>
                <button className="smallColorfulButton" onClick={() => {
                  let price = (parseFloat(destinationPlanet.max_pool) * (claimplanetc / 100)).toFixed(2);

                  if (price < parseFloat(stakedBalance)) {
                    claimPlanet(selectedStarShip, shipData.planet);
                  } else {
                      toast.error('Not enough Kyanite',{
                        position: toast.POSITION.TOP_CENTER
                      });
                  }
                }}>Claim Planet</button>
              </div>
            );
          }

          switch (destinationPlanet.type.toUpperCase()) {
            case ('DESERT'):
              backgroundImage = (
                <img src='bridgeDesert.jpg' alt="Desert Planet" className="desktopBackground" />
              );
              break;
            case ('GRASSLAND'):
              backgroundImage = (
                <img src='bridgeGreenland.jpg' alt="Grassland Planet" className="desktopBackground" />
              );
              break;
            case ('CRYSTAL'):
              backgroundImage = (
                <img src='bridgeCrystal.jpg' alt="Crystal Planet" className="desktopBackground" />
              );
              break;
            case ('GAS'):
              backgroundImage = (
                <img src='bridgeGas.jpg' alt="Gas Planet" className="desktopBackground" />
              );
              break;
            case ('GLACIER'):
              backgroundImage = (
                <img src='bridgeIce.jpg' alt="Ice Planet" className="desktopBackground" />
              );
              break;
            case ('MAGMA'):
              backgroundImage = (
                <img src='bridgeMagma.jpg' alt="Magma Planet" className="desktopBackground" />
              );
              break;
            case ('OCEAN'):
              backgroundImage = (
                <img src='bridgeOcean.jpg' alt="Ocean Planet" className="desktopBackground" />
              );
              break;
            case ('PARADISE'):
              backgroundImage = (
                <img src='bridgeParadise.jpg' alt="Paradise Planet" className="desktopBackground" />
              );
              break;
            case ('SWAMP'):
              backgroundImage = (
                <img src='bridgeSwamp.jpg' alt="Swamp Planet" className="desktopBackground" />
              );
              break;
            case ('METALIC'):
            case ('METALLIC'):
            backgroundImage = (
              <img src='bridgeMetal.jpg' alt='Metallic Planet' className='desktopBackground' />
            );
            break;
          }
        }
      }
    } else {
      if (timeDiff > 0) {
        destinationImage = (
          <img src={spaceStationInfo()[shipData.spacestation].image} alt={spaceStationInfo()[shipData.spacestation].name} className="imgDestination" />
        );

        destinationInfo = (
          <div className="divDestinationInfo">
            <div style={{paddingLeft: '70px', paddingTop: '20px'}}>
              <h3 style={{marginBottom: '0.025em'}}>Returning to Space Station</h3>
              <h3 style={{marginTop: '0.025em', marginBottom: '0.025em'}}>Ship {selectedStarShip}</h3>
              <h3 style={{marginTop: '0.025em', marginBottom: '0.025em'}}>{spaceStationInfo()[shipData.spacestation].name}</h3>
              <h3 style={{marginTop: '0.025em', marginBottom: '0.025em'}}>{timeLeftText}</h3>
              <div style={{paddingLeft: '25%'}}>
                {destinationImage}
              </div>
            </div>
          </div>
        );

        backgroundImage = (
          <img src='shipTravelling.gif' className="desktopBackground" />
        );
      } else {
        btnSendToSpaceStation = (
          <div style={{display: 'inline-block'}}>
            <button className="smallColorfulButton" onClick={() => {
              setModalContentState(1);
              openModal();
            }}>Send to Space Station</button>
          </div>

        );
        btnSendOnMission = (
          <div style={{display: 'inline-block'}}>
            <button className="smallColorfulButton" onClick={() => {
              setSelectedSpaceStation(shipData.spacestation);
              setModalContentState(0);
              openModal();
            }}>Send on Mission</button>
          </div>
        );

        backgroundImage = (
          <img src='bridgeInHangar.jpg' className="desktopBackground" />
        );
      }
    }



    switch (modalContentState) {
      case 0:
        const quadrantOptions = [
          { value: '0', label: 'North' },
          { value: '3', label: 'South' },
          { value: '2', label: 'East' },
          { value: '1', label: 'West' }
          ];

        let numbers = [];

        for (var i=0; i<25; i++) {
          numbers.push(i);
        }

        const sectorOptions = numbers.map((number, i) => {
          return { value: number, label: number };
        });

        const systemOptions = numbers.map((number, i) => {
          return { value: number, label: number };
        });

          modalContent = (
            <div style={{width: '100%'}}>
              <h2 style={{textAlign:"center"}} ref={(_subtitle) => (subtitle = _subtitle)}>Select Mission</h2>

              <div className="divMissionSelect">
                Quadrant<br />
                <Select styles={customOptionStyles} onChange={async (selectedOption) => {
                  setSelectedQuadrant(selectedOption.value);
                }} defaultValue={selectedQuadrant} options={quadrantOptions} placeholder="Select Quadrant" /><br />
                Sector<br />
                <Select styles={customOptionStyles} onChange={async (selectedOption) => {
                  setSelectedSector(selectedOption.value);
                }} defaultValue={selectedSector} options={sectorOptions} placeholder="Select Sector" />
                Distance: {(parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)} Parsecs<br />
                Time to travel: {fancyTimeFormat(((parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)) * shipData.speed)}<br /><br />
                System<br />
                <Select styles={customOptionStyles} onChange={async (selectedOption) => {
                  setSelectedSystem(selectedOption.value);
                  //await getPlanets();
                }} defaultValue={selectedSystem} options={systemOptions} placeholder="Select System" /><br />
                  <br />
                  <div className="button" style={{marginLeft: 'auto', marginRight: 'auto'}} onClick={async () => {
                  launchMission(selectedStarShip);
                  closeModal();
                }}>
                  <p>Launch</p>
                </div>
                <br />
                <h2 style={{textAlign:"center"}}>Select Planet</h2>
                <br />
                {planets.map((planet, i) => {
                  let planetCards = [];

                  if (planetData.length > 0) {
                    for (let j=0; j<planetData.length; j++) {
                      let planetImage;
                      let claimPrice = '';

                      if (planetData[j].owner === contractAddress) {
                        claimPrice = `Claim Price: ${(parseFloat(planetData[j].max_pool) * parseFloat(claimplanetc / 100)).toFixed(4)}`;
                      }

                      switch(planetData[j].type.toUpperCase()) {
                        case ('CRYSTAL'):
                          planetImage = <img src={crystalPlanet} alt="Crystal" />
                          break;
                        case ('GAS'):
                          planetImage = <img src={gasPlanet} alt="Gas" />
                          break;
                        case ('DESERT'):
                          planetImage = <img src={desertPlanet} alt="Desert" />
                          break;
                        case ('GLACIER'):
                          planetImage = <img src={icePlanet} alt="Ice" />
                          break;
                        case ('MAGMA'):
                          planetImage = <img src={magmaPlanet} alt="Magma" />
                          break;
                        case ('SWAMP'):
                          planetImage = <img src={swampPlanet} alt="Swamp" />
                          break;
                        case ('OCEAN'):
                          planetImage = <img src={oceanPlanet} alt="Ocean" />
                          break;
                        case ('GRASSLAND'):
                          planetImage = <img src={greenlandPlanet} alt="Grassland" />
                          break;
                        case ('PARADISE'):
                          planetImage = <img src={paradisePlanet} alt="Paradise" />
                          break;
                        case ('METALLIC'):
                          planetImage = <img src={metallicPlanet} alt="Metallic" />
                          break;
                      }

                      let planetName = toTitleCase(planetData[j].type)  + ' ' + planetData[j].id;
                      if (!(checkMaxedOutPlanet(planetData[j].type, planetData[j].max_pool))) {
                        planetName = "Mega " + planetName;
                      }

                      planetCards[j] = (
                        <div className={planetData[j].id === selectedPlanet ? 'planetCardSelectedMissionSelect' : 'planetCardMissionSelect'} onClick={() => {
                          setSelectedPlanet(planetData[j].id);
                        }}>
                          <div>
                            {planetImage}
                            <h2 style={{display: 'inline-block', width: 'auto', float: 'right'}}>{planetName}</h2>
                          </div>
                          <div>
                            Pool: {planetData[j].next_reset > today / 1000 ? parseFloat(planetData[j].pool).toFixed(0) : (planetData[j].owner === contractAddress ? Math.floor(parseFloat(planetData[j].max_pool).toFixed(0) * 0.2) : parseFloat(planetData[j].max_pool).toFixed(0))} / {parseFloat(planetData[j].max_pool).toFixed(0)}
                          </div>
                          <div>
                            Resets: {(planetData[j].next_reset > new Date() / 1000) ? convertUnixTime(planetData[j].next_reset) : convertUnixTime(tomorrow / 1000)}
                          </div>
                          <div>
                            {claimPrice}
                          </div>
                          <div>
                            Owner: {planetData[j].owner === contractAddress ? 'Available' : planetData[j].owner}
                          </div>
                          <div>
                            Fuel Cost: {(((parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)) * parseFloat(shipData.consumption)).toFixed(2)} ({(((parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)) * parseFloat(shipData.consumption)).toFixed(2) / 4} if ship is a rental)
                          </div>
                        </div>
                      );
                    }
                  } else {
                    planetCards[0] = <span></span>;
                    planetCards[1] = <span></span>;
                    planetCards[2] = <span></span>;
                    planetCards[3] = <span></span>;
                  }

                  return (
                    <div key={i} style={{display: 'inline-block', width: '40%', marginLeft: '2.0em', marginRight: '2.0em', marginBottom: '0.5em'}}>
                      {planetCards[i]}
                    </div>
                  );
                })}<br />
              </div>
            </div>
          );
        break;
      case 1:
        modalContent = (
          <div className="divSpaceStationLauncher">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Select Space Station</h2>
            <select onChange={event => {
              if (event.target.value !== '') {
                setSelectedSpaceStation(event.target.value);
              } else {
                setSelectedSpaceStation({});
              }
            }}>
              <option value="">Select Space Station</option>
              {spaceStationInfo().map((ss, i) => {
                return (
                  <option value={i} key={i}>{ss.name}</option>
                );
              })}
            </select><br />
            <button onClick={() => {
              sendToSpaceStation(selectedStarShip);
              closeModal();
            }} className="bigColorfulButton">Launch</button>
          </div>
        );
        break;
        case 2:

              //write a style for upgrade card
  let upgradeCardBorder = {
    border: '2px solid #000',
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    margin: '10px',
    padding: '10px',
    textAlign: 'center',
    verticalAlign: 'top',
    display: 'flex',
    backgroundColor: 'rgb(23 6 56 / 67%)',
    boxShadow: '0 0 10px #000',
    //align child elements vertically in the middle
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',





    

  }

  let upgradeImg = {
    //image should be background image of div
    width: '200px',
    height: 'auto',
    display: 'inline-block',
    margin: '10px',
    textAlign: 'center',
    verticalAlign: 'top',
    //add shadow
    boxShadow: '0 0 10px #000'

  }

  let upgradeMiddleStyle = {
    //inside should be vertically aligned
    width: 'auto',
    height: '%100',
    margin: '10px',
    textAlign: 'top',
    //align vertically in the middle
    display: 'inline-block',
  }

  function solveAutoReturn(status) {
    if (status === 0) {
      return 'No';
    } else if (status === 1) {
      return 'Yes';
    } else {
      return 'Unknown';
    }
  }

  let UpgradePanelStyle = {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    verticalAlign: 'top',
    marginTop: '-50px',
    //horizontal align in the middle of child 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',


  }
  
  let upgradeButtonStyle = {
    //style a space themed button
    width: '200px',
    //horizontally align the button
    margin: 'auto',
    
    height: '2em',
    textAlign: 'center',
    fontSize: '1em',
    verticalAlign: 'top',
    //horizontal align in the middle of child
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //add shadow
    boxShadow: '0 0 10px #000',
    //add border
    border: '2px solid #000',
    borderRadius: '10px',
    //add background
    backgroundColor: 'white',
  }

  let width33style = {

      display: "inline-block",
      width: "33%",
      marginLeft: "1.0em",
      marginRight: "1.0em",
      marginBottom: "0.5em",
    }


    
          modalContent = (

            
    
            <div> 
            <div style={UpgradePanelStyle}>
              <h1  style={{marginBottom:"0px", color: "white"}} ref={(_subtitle) => (subtitle = _subtitle)}></h1>
              <h1 style={{marginTop: "0px", marginBottom: "20px"}}>Planet {destinationPlanet.id} Enhance Panel</h1>
              <h3 style={{marginTop: "0px", marginBottom: "20px"}}>Sacrifice an nft in your account to enhance your planet's maximum pool to its limits!</h3>
              {console.log(planetUpgConfig)}
              { console.log(userAssets) }
              <div style={upgradeCardBorder} key={i}>
              <div
                      style={width33style}
                    >
                      <div> Active Pool:<br /> {destinationPlanet.next_reset > today / 1000 ? parseFloat(destinationPlanet.pool).toFixed(0) : (destinationPlanet.owner === contractAddress ? Math.floor(parseFloat(destinationPlanet.max_pool).toFixed(0) * 0.2) : parseFloat(destinationPlanet.max_pool).toFixed(0))}</div>
                    </div>
                    <div
                      style={width33style}
                    >
                      <div> Current Max Pool:<br />{Math.floor(destinationPlanet.max_pool)} </div>
                    </div>
                    <div
                      style={width33style}
                    >
                      <div> Potential Max Pool:<br />{
                        //loop in planetpoolconfig
                        planetPoolConfig.map((planet) => {
                          if (planet.type === destinationPlanet.type) {
                            return Math.floor(planet.max_pool);
                          }

                        })
                        } </div>
                    </div>
                </div>


                        
                <div style={upgradeCardBorder} key={i}>
              <div
                      style={width33style}
                    >
                      <div> 
                         <img src={
                          process.env.PUBLIC_URL + '/kyanite-upgrade.jpg'
                         } style={upgradeImg} alt="kyanite upgrade" />
                         </div>
                    </div>
                    <div
                      style={width33style}
                    >
                      <div style={upgradeMiddleStyle}>

                      <h1 style={{marginTop: "0px", marginBottom:"0px"}}>Upgrade With Kyanite</h1>
                      <h3 style={{marginTop:"0px"}}>+%10 of Current Max Pool</h3>
                      <button style={upgradeButtonStyle}
                        onClick={() => {
                          upgradePlanetwithKya();
                        }}
                      >
                        Enhance Planet
                      </button>
                      </div>
                    </div>
                    <div
                      style={width33style}
                    >
                      <div key={i}>
                              <h3>Kyanite Cost: {Math.floor(kyaniteUpgradeCostMultiplier*destinationPlanet.max_pool)}</h3>
                              <h3>Enhance Amount: +{solveEnhanceAmount(destinationPlanet.max_pool)}</h3>
                              <h4 style={{fontStyle:"italic"}}>Cost is equal to current active pool of the planet.</h4>
                            </div>
                    </div>
                </div>
              
              
              {//filter if the userAssets template ids match the planetPoolConfig template ids
              
              filterUserAssets(userAssets).filter((asset) => {


                planetPoolConf = planetPoolConfig.find((planet) => {
                  return planet.type === destinationPlanet.type;
                })
                
                return planetUpgConfig.filter((upg) => {
                  
                  return upg.template_id.toString() === asset.template.template_id.toString() && upg.isactive === 1 ;
                 

                }).length > 0;
              }).map((asset, i) => {
                return (
                  <div style={upgradeCardBorder} key={i}>
                    <div
                      style={width33style}
                    >
                      <img
                        style={upgradeImg}
                        src={`https://${ipfsNode}/ipfs/${asset.template.immutable_data.img}`}
                        alt=""
                      />
                    </div>
                    <div
                      style={width33style}
                    >
                      <div style={upgradeMiddleStyle}>
                      <p>{asset.asset_id}</p>
                      <h1 style={{marginTop: "0px", marginBottom:"0px"}}>{asset.data.name}</h1>
                      <h3 style={{marginTop:"0px"}}>Rarity: {asset.template.immutable_data.rarity || asset.template.immutable_data.Rarity}</h3>
                      <button style={upgradeButtonStyle}
                        onClick={() => {
                          upgradePlanet(asset.asset_id);
                        }}
                      >
                        Enhance Planet
                      </button>
                      </div>
                    </div>
                    <div
                      style={width33style}
                    >
                      {
                        planetUpgConfig.filter((upg) => {
                          return upg.template_id.toString() === asset.template.template_id.toString() && upg.isactive === 1;
                        }
                        ).map((upg, i) => {
                          //find the planetpoolconfig that matches the asset template id

                          
                          if (upg.amountorpercentage === 0) {
                             upgSubArea = (
                              <div key={i}>
                              <h3>Enhance Amount +{upg.up_amount}</h3>
                              <h3>Force Return: {solveAutoReturn(upg.auto_return)}</h3>
                            </div>
                            )
                          }
                          else {  
                            upgSubArea = (
                              <div key={i}>
                                

                              <h3>Enhance Percentage: %{(100*(upg.up_percentage)).toFixed(2)}</h3>
                              <h3>Enhance Amount: +{Math.round(upg.up_percentage*planetPoolConf.max_pool)}</h3>
                              <h3>Force Return: {solveAutoReturn(upg.auto_return)}</h3>
                              


                            </div>
                            )
                          }
                          if (upg.auto_return === 1) {
                            //add a new line to div key i in upgSubArea
                            

                            upgSubArea = (
                              <div key={i}>
                              <h3>Enhance Percentage: %{(100*(upg.up_percentage)).toFixed(2)}</h3>
                              <h3>Enhance Amount: +{Math.round(upg.up_percentage*planetPoolConf.max_pool)}</h3>
                              <h3>Force Return: {solveAutoReturn(upg.auto_return)}</h3>
                              <h4 style={{fontStyle:"italic"}}>Ships will mine before being forced to return</h4>
                              </div>
                            )
                          }
                        }
                        )
                      }
                      {upgSubArea}
                    </div>
                  </div>
                );
              })}
        
            </div>
            </div>
          );
        break;
    }
  }

  

  return (
    <div>
      <div className="divMissionsDesktop">
        <div className="divDesktopBackground">
          {backgroundImage}
        </div>
        <div className="divDesktopContent">
            {fleet.map((starShip, i) => {
              if (selectedStarShip === starShip.key) {
                let maxProfitSector = Math.floor(((parseFloat(starShip.storage_capacity) * rewardMod) / parseFloat((parseFloat(starShip.mining_capacity) + (parseFloat(starShip.consumption)*2)) * rewardMod)) / sectorsize) - 1;

                if (maxProfitSector > 24) {
                  maxProfitSector = 24;
                }

                return (
                  <div className="divShipDescription">
                    <div style={{paddingLeft: '70px', paddingTop: '20px'}}>
                      <h3 style={{marginBottom: '0.25em'}}>{rarity(starShip.rarity)} Ship {starShip.key}</h3>
                      {spaceStationInfo()[starShip.spacestation].name}<br />
                      <table cellSpacing="1" cellPadding="0">
                        <tbody>
                          <tr>
                            <td>Consumption</td>
                            <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.consumption).toFixed(4)} Ky/Ps</td>
                          </tr>
                          <tr>
                            <td>Staking Capacity</td>
                            <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.staking_capacity).toFixed(4)} Ky/Hr</td>
                          </tr>
                          <tr>
                            <td>Mining Capacity</td>
                            <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.mining_capacity).toFixed(4)} Ky/Ps</td>
                          </tr>
                          <tr>
                            <td>Storage Capacity</td>
                            <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.storage_capacity * rewardMod).toFixed(4)} Ky</td>
                          </tr>
                          <tr>
                            <td>Stat Speed</td>
                            <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.speed).toFixed(4)} min/Ps</td>
                          </tr>
                          <tr>
                            <td>Hourly Speed:</td>
                            <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(60/parseFloat(starShip.speed)).toFixed(2)} Ps/hr</td>
                          </tr>
                          <tr>
                            <td>Max Profit Sector:</td>
                            <td style={{textAlign: 'right', paddingLeft: '15px'}}>{maxProfitSector}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              }
            })}

            {rentedShips.map((starShip, i) => {
              if (selectedStarShip === starShip.key) {
                let maxProfitSector = Math.floor(((parseFloat(starShip.storage_capacity) * rewardMod) / parseFloat((parseFloat(starShip.mining_capacity) + (parseFloat(starShip.consumption)*2)) * rewardMod)) / sectorsize) - 1;

                if (maxProfitSector > 24) {
                  maxProfitSector = 24;
                }

                return (
                  <div className="divShipDescription">
                    <div style={{paddingLeft: '70px', paddingTop: '20px'}}>
                      <h3 style={{marginBottom: '0.25em'}}>{rarity(starShip.rarity)} Ship {starShip.key}</h3>
                      {spaceStationInfo()[starShip.spacestation].name}<br />
                      <table cellSpacing="1" cellPadding="0">
                        <tbody>
                        <tr>
                          <td>Consumption</td>
                          <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.consumption).toFixed(4)} Ky/Ps</td>
                        </tr>
                        <tr>
                          <td>Staking Capacity</td>
                          <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.staking_capacity).toFixed(4)} Ky/Hr</td>
                        </tr>
                        <tr>
                          <td>Mining Capacity</td>
                          <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.mining_capacity).toFixed(4)} Ky/Ps</td>
                        </tr>
                        <tr>
                          <td>Storage Capacity</td>
                          <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.storage_capacity * rewardMod).toFixed(4)} Ky</td>
                        </tr>
                        <tr>
                          <td>Stat Speed</td>
                          <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(starShip.speed).toFixed(4)} min/Ps</td>
                        </tr>
                        <tr>
                          <td>Hourly Speed:</td>
                          <td style={{textAlign: 'right', paddingLeft: '15px'}}>{parseFloat(60/parseFloat(starShip.speed)).toFixed(2)} Ps/hr</td>
                        </tr>
                        <tr>
                          <td>Max Profit Sector:</td>
                          <td style={{textAlign: 'right', paddingLeft: '15px'}}>{maxProfitSector}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              }
            })}
            {destinationInfo}
        </div>
        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <div className="divShipSelector">
            {fleet.map((starShipData, j) => {
              return (
                <AvailableShip isRented="false" starShipData={starShipData} setSelectedStarShip={setSelectedStarShip} selectedStarShip={selectedStarShip} />
              );
            })}<br />
            {rentedShips.map((starShipData, j) => {
              return (
                <AvailableShip isRented="true" starShipData={starShipData} setSelectedStarShip={setSelectedStarShip} selectedStarShip={selectedStarShip} />
              );
            })}
          </div>
          <div className="divSelectedStarShipStatus">
            <div style={{position: 'relative', left: '-70%'}}>
              {btnMinePlanet} {btnClaimPlanet} {btnSendToSpaceStation} {btnSendOnMission} {btnUpgPlanet}
            </div>
          </div>
          <img src={controlPanelBackground} alt="" />

          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
            let waxUserAccount = '';
            let player = '';

            if (anchorWax) {
              let { session } = identity;
              waxUserAccount = session.auth.actor;
              player = session.auth.actor;
            } else {
              waxUserAccount = wax.userAccount;
              player = wax.userAccount;
            }

            let actions = [
              {
                account: contractAddress,
                name: 'claim',
                authorization: [{
                  actor: waxUserAccount,
                  permission: 'active',
                }],
                data: {
                  player: player
                },
              }
            ];

            try {
              let result;
              if (cloudWallet) {
                result = await wax.api.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                });
              } else {
                let { session } = identity;
                result = await session.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                })
              }

              toast.success('Kyanite Claimed!',{
                position: toast.POSITION.TOP_CENTER
              });
            } catch(ex) {
              toast.error(ex,{
                position: toast.POSITION.TOP_CENTER
              });
            }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Select Parts">
          <div>
            <button className="bigColorfulButton" onClick={closeModal}>Close</button>
            {ShowHideButton}
          </div>
          <div>
            
          </div>

          <div className="modalContent">
            {modalContent}
          </div>

        </Modal>
      </div>

      <div className="divMissionsMobile">
        <h1 className="screenTitle">Missions</h1>
        <div>
          {fleet.map((starShip, i) => {
            return (
              <ShipData rewardMod={rewardMod} spacecommis={spacecommis} sectorsize={sectorsize} planetreward={planetreward} planetcommis={planetcommis} interdistanc={sectorsize} claimplanetc={claimplanetc} claimperiod={claimperiod} cloudWallet={cloudWallet} identity={identity} claimPlanet={claimPlanet} minePlanet={minePlanet} launchMission={launchMission} sendToSpaceStation={sendToSpaceStation} anchorWax={anchorWax} stakedBalance={stakedBalance} toast={toast} wax={wax} key={i} starShip={starShip.key} starShipData={starShip} />
            );
          })}
          {rentedShips.map((starShip, i) => {
            return (
              <ShipData planetUpgConfig={planetUpgConfig} userAssets={userAssets} planetPoolConfig={planetPoolConfig} upgradePlanet={upgradePlanet} rewardMod={rewardMod} spacecommis={spacecommis} sectorsize={sectorsize} planetreward={planetreward} planetcommis={planetcommis} interdistanc={sectorsize} claimplanetc={claimplanetc} claimperiod={claimperiod} cloudWallet={cloudWallet} identity={identity} claimPlanet={claimPlanet} minePlanet={minePlanet} launchMission={launchMission} sendToSpaceStation={sendToSpaceStation} anchorWax={anchorWax} stakedBalance={stakedBalance} toast={toast} wax={wax} key={i} starShip={starShip.key} starShipData={starShip} />
            );
          })}
        </div>
      </div>
      <div style={{paddingTop: '3em'}}>
        &nbsp;
      </div>
    </div>
  );
}
