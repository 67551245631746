/* eslint-disable */
// fetchTopKyaniteHolders.worker.js
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (e) => {
    if (e.data === 'close') {
      self.close();
    } else {
      let url = e.data[0];
      let stationId = e.data[1];

      fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'text/plain',
          'X-Requested-With': 'JSON',
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => response.json())
      .then(data => {
        let returnData = [];
        let present = false;

        for (let i=0; i<data.length; i++) {
          present = false;

          for (let j=0; j<returnData.length; j++) {
            if (returnData[j].player === data[i].player) {
              present = true;
            }
          }

          if (!present) {
            returnData.push(data[i]);
          }

          if (returnData.length >= 10) {
            break;
          }
        }

        postMessage(returnData);
      });
    }
  };
};
