import { BrowserView } from 'react-device-detect';
import {
	getFleetRPC,
	getEndPoints,
	isProd,
	enviromentVariables,
	contractAddress,
	convertUnixTime,
	sessionEndpoint,
	collectionName
} from './helpers';
import './App.scss';
import { ethers } from 'ethers';
import Buffer from 'buffer';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import smallLogo from './images/smallLogo.png';
import robot from './images/robot.png';
import redX from './images/redX.png';
import * as waxjs from "@waxio/waxjs/dist";
import WorkerBuilder from './workers/worker-builder';
import CheckAwardsWorker from './workers/checkAwards.worker';
import FetchTokenWorker from './workers/fetchtoken.worker';
import FetchBalanceWorker from './workers/fetchBalance.worker';
import FetchStationData from './workers/fetchStationInfo.worker';
import FetchPlayerInfoWorker from './workers/fetchPlayerInfo.worker';
import FetchPlayerAssetsWorker from './workers/fetchAssets.worker';
import FetchEquippedPartsWorker from './workers/fetchEquippedParts.worker.js';
import FetchFleetWorker from './workers/fetchFleet.worker.js';
import FetchRentedShips from './workers/fetchRentedShips.worker.js';
import FetchDestinationPlanet from './workers/fetchDestinationPlanet.worker.js';
import MobileMenu from './components/mobileMenu';
import Station from './components/station';
import Hangar from './components/hangar';
import Missions from './components/missions';
import Planets from './components/planets';
import StakeWithdraw from './components/stakeWithdraw';
import Rental from './components/rentals';
import Leaderboards from './components/leaderboards';
import Shop from './components/shop';
import {toast} from 'react-toastify';
import './ReactToastify.css';
import AnchorLink from 'anchor-link'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'
import Adarea1 from './components/adarea1';
import Web3 from 'web3';
import { ExplorerApi } from "atomicassets";

let pancakeSwapAbi =  [
  {"inputs":[{"internalType":"uint256","name":"amountIn","type":"uint256"},{"internalType":"address[]","name":"path","type":"address[]"}],"name":"getAmountsOut","outputs":[{"internalType":"uint256[]","name":"amounts","type":"uint256[]"}],"stateMutability":"view","type":"function"},
  ];
  let tokenAbi = [
  {"inputs":[],"name":"decimals","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},
  ];

const { ethereum } = window;
const fetchTokenInstance = new WorkerBuilder(FetchTokenWorker);
Modal.setAppElement(document.getElementById('root'));
toast.configure();

// Setup AnchorLink
const transport = new AnchorLinkBrowserTransport();
const link = new AnchorLink({
    transport,
    chains: [
        {
            chainId: enviromentVariables().chainId,
            // nodeUrl: enviromentVariables().nodeUrl,
            nodeUrl: sessionEndpoint,
        }
    ],
});

function App() {
  let subtitle;
  let modalContent;
  let metaMaskInstalledText = '';
  let metaMaskConnectedText = '';
  let starshipBalanceText = '';
  let waxAccountText = '';
  const [modalIsOpen, setIsOpen] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [waxId, setWaxId] = useState('');
  const [gameScreen, setGameScreen] = useState(0);
  const [userToken, setUserToken] = useState(null);
  const [availableBalance, setAvailableBalance] = useState('0.00 Kyanite');
  const [fleet, setFleet] = useState([]);
  const [rentedShips, setRentedShips] = useState([]);
  const [selectedStarShip, setSelectedStarShip] = useState(0);
  const [stakedBalance, setStakedBalance] = useState('0.00 Kyanite');
  const [starShipPrice, setStarShipPrice] = useState(0.00);
  const [starShips, setStarShips] = useState([]);
  const [shipData, setShipData] = useState({parts: []});
  const [stationData, setStationData] = useState([]);
  const [components, setComponents] = useState([]);
  const [userAssets , setUserAssets] = useState([]);
  const [identity, setIdentity] = useState();
  const [destinationPlanet, setDestinationPlanet] = useState(null);
  const [fetchingFleet, setFetchingFleet] = useState(false);
  const [fetchingToken, setFetchingToken] = useState(false);
  const [fetchingKyaniteBalance, setFetchingKyaniteBalance] = useState(false);
  const [fetchingUserInfo, setFetchingUserInfo] = useState(false);
  const [fetchingComponents, setFetchingComponents] = useState(false);
  const [fetchingShipData, setFetchingShipData] = useState(false);
  const [fetchingStationData, setFetchingStationData] = useState(false);
  const [ready, setReady] = useState(false);
  const [rewards, setRewards] = useState(false);
  const [rewardCount, setSetRewardCount] = useState(0);
  const [fetchingDestinationPlanetData, setFetchingDestinationPlanetData] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [equippedParts, setEquippedParts] = useState([]);
  const [lastClaim, setLastClaim] = useState(null);
  const [planetUpgConfig, setPlanetUpgConfig] = useState([]);
  const [planetPoolConfig, setPlanetPoolConfig] = useState([]);

  //const proxy = 'https://agile-plateau-18437.herokuapp.com/';
  const proxy = '';

  // Create a new WAX instance
  const [cloudWallet, setCloudWallet] = useState(false);
  const [wax, setWax] = useState(new waxjs.WaxJS({
    // rpcEndpoint: enviromentVariables().nodeUrl,
    rpcEndpoint: sessionEndpoint,
    tryAutoLogin: false
  }));

  let exp_api_list = [
    "https://atomic.wax.eosrio.io",
    "https://wax-aa.eu.eosamsterdam.net",
    "https://wax.api.atomicassets.io",
    "https://wax-atomic-api.eosphere.io",
    "https://api.atomic.greeneosio.com",
  ];
  let expApi = null;


  if(process.env.REACT_APP_ENV === 'development') {
    expApi = new ExplorerApi(
      "https://test.wax.api.atomicassets.io",
      "atomicassets",
      {
        fetch,
      }
    );
  } else {
    expApi = new ExplorerApi(
      exp_api_list[Math.floor(Math.random() * exp_api_list.length)],
      "atomicassets",
      {
        fetch,
      }
    );
  }
  



  const [anchorWax, setAnchorWax] = useState(false);

  let rewardsIndicator = '';

  function getEquippedComponents() {
    if (typeof shipData !== 'undefined' && typeof shipData.parts !== 'undefined' && shipData.parts.length !== 0) {
      fetch(`https://${enviromentVariables().atomichub}/atomicassets/v1/assets/?ids=${shipData.parts.join()}&sort=name&order=asc`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'text/plain; charset=utf-8'
        }
      })
      .then(response => response.json())
      .then(data => {
        if (typeof(data.data) !== 'undefined') {
          setEquippedParts(data.data);
        }
      });
    }
  }


  async function getStarshipPrice() {
    let pancakeSwapContract = "0x10ED43C718714eb63d5aA57B78B54704E256024E".toLowerCase();
  const web3 = new Web3("https://bsc-dataseed1.binance.org");
  async function calcSell( tokensToSell, tokenAddres){
      const web3 = new Web3("https://bsc-dataseed1.binance.org");
      const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" //BNB
  
      let tokenRouter = await new web3.eth.Contract( tokenAbi, tokenAddres );
      let tokenDecimals = await tokenRouter.methods.decimals().call();
      
      tokensToSell = setDecimals(tokensToSell, tokenDecimals);
      let amountOut;
      try {
          let router = await new web3.eth.Contract( pancakeSwapAbi, pancakeSwapContract );
          amountOut = await router.methods.getAmountsOut(tokensToSell, [tokenAddres ,BNBTokenAddress]).call();
          amountOut =  web3.utils.fromWei(amountOut[1]);
      } catch (error) {}
      
      if(!amountOut) return 0;
      return amountOut;
  }
  async function calcBNBPrice(){
      const web3 = new Web3("https://bsc-dataseed1.binance.org");
      const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" //BNB
      const USDTokenAddress  = "0x55d398326f99059fF775485246999027B3197955" //USDT
      let bnbToSell = web3.utils.toWei("1", "ether") ;
      let amountOut;
      try {
          let router = await new web3.eth.Contract( pancakeSwapAbi, pancakeSwapContract );
          amountOut = await router.methods.getAmountsOut(bnbToSell, [BNBTokenAddress ,USDTokenAddress]).call();
          amountOut =  web3.utils.fromWei(amountOut[1]);
      } catch (error) {}
      if(!amountOut) return 0;
      return amountOut;
  }
  function setDecimals( number, decimals ){
      number = number.toString();
      let numberAbs = number.split('.')[0]
      let numberDecimals = number.split('.')[1] ? number.split('.')[1] : '';
      while( numberDecimals.length < decimals ){
          numberDecimals += "0";
      }
      return numberAbs + numberDecimals;
  }

  (async () => {
      const tokenAddres = '0x52419258E3fa44DEAc7E670eaDD4c892B480A805'; // change this with the token addres that you want to know the 
      let bnbPrice = await calcBNBPrice() // query pancakeswap to get the price of BNB in USDT
      // Them amount of tokens to sell. adjust this value based on you need, you can encounter errors with high supply tokens when this value is 1.
      let tokens_to_sell = 1; 
      let priceInBnb = await calcSell(tokens_to_sell, tokenAddres)/tokens_to_sell; // calculate TOKEN price in BNB
      return priceInBnb*bnbPrice;

  })
  ().then((value) => {
    setStarShipPrice(value);
  });



  }

  function checkRewards() {
    let checkAwardsInstance = new WorkerBuilder(CheckAwardsWorker);
    checkAwardsInstance.onmessage = (message) => {
      if (message) {
        checkAwardsInstance.postMessage('close');

        if (typeof message.data !== 'undefined') {
          if (typeof message.data.rows !== 'undefined') {
            if (message.data.rows.length > 0) {
            console.log('rewards available', message.data.rows)
              setRewards(true);
              setSetRewardCount(message.data.rows.length);
            } else {
              setRewards(false);
              setSetRewardCount(0);
            }
          }
        }
      }
    };

    let waxUserAccount = '';
    if (anchorWax) {
      if (typeof identity !== 'undefined') {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      }
    } else {
      waxUserAccount = wax.userAccount;
    }

    if (waxUserAccount !== '') {
      console.debug(`Checking rewards for ${waxUserAccount}`);
      // checkAwardsInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
      checkAwardsInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
    }
  }

  function getDestinationPlanet(id) {
      let fetchDestinationPlanetInstance = new WorkerBuilder(FetchDestinationPlanet);
      fetchDestinationPlanetInstance.onmessage = (message) => {
        if (message) {
          fetchDestinationPlanetInstance.postMessage('close');

          if (typeof message.data !== 'undefined') {
            if (typeof message.data.rows !== 'undefined') {
              setDestinationPlanet(message.data.rows[0]);
            }
          }
        }
      };

      console.debug(`Fetching Planet ${id}`);
      fetchDestinationPlanetInstance.postMessage([`${proxy}${sessionEndpoint}/v1/chain/get_table_rows`, id, contractAddress]);
  }

  useEffect(async () => {


    await getStarshipPrice();
    console.log('starship price', starShipPrice);



    //! WORK AREA!!!


    
    




    

        //! WORK AREA!!!

    if (fleet !== null) {
     fleet.map((ship, i) => {
        if (ship.key === selectedStarShip) {
            getDestinationPlanet(ship.planet);
        }
      });
    }

    if (rentedShips !== null) {
     rentedShips.map((ship, i) => {
        if (ship.key === selectedStarShip) {
            getDestinationPlanet(ship.planet);
        }
      });
    }

    // Update the fleet and kyanite balance every thirty seconds
    if (!fetching && anchorWax) {
      getFleet(identity);
      getRentedShips();
      checkRewards();

      //if (components.length === 0) {
      //  getComponents();
      //}

      setInterval(function(){
        getKyaniteBalance();
        getFleet(identity);
        getRentedShips();
        getUserInfo();
      }, 30000);

      // Check for rewards every half hour
      setInterval(async function(){
        checkRewards();

        await getStarshipPrice();
        console.log('starship price in usd', starShipPrice)
      }, 1800000);

      setFetching(true);
    }

    // Update the fleet and kyanite balance every thirty seconds
    if (!fetching && cloudWallet) {
      checkRewards();
      getRentedShips();

      setInterval(function(){
        getKyaniteBalance();
        getFleet();
        getRentedShips();
        getUserInfo();
      }, 30000);

      // Check for rewards every half hour
      setInterval(async function(){
        checkRewards();

        await getStarshipPrice();
        console.log('starship price in usd', starShipPrice)
      }, 1800000);

      setFetching(true);
    }
  }, [selectedStarShip, identity, anchorWax, cloudWallet, components]);

  function connectWallet() {
    if (ethereum) {
      ethereum.request({method: 'eth_requestAccounts'})
      .then(result => {
        setDefaultAccount(result[0]);
      });
    }
  }

  async function getUserBalance(address) {
    let minABI = [
      // Read-Only Functions
      "function balanceOf(address owner) view returns (uint256)",
      "function decimals() view returns (uint8)",
      "function symbol() view returns (string)",
    ];

    // Contract address of $STARSHIP
    let contract = '0x52419258E3fa44DEAc7E670eaDD4c892B480A805';
    let provider = new ethers.providers.Web3Provider(ethereum);
    let tokenContract = await new ethers.Contract(contract, minABI, provider);

    if (typeof tokenContract != 'undefined' && tokenContract != null) {
      let balance = await tokenContract.balanceOf(address);
      if (typeof balance != 'undefined' && balance != null) {
        setUserBalance(balance/1000000000);
      }
    }
  }

  function setUser(wuid) {
    setWaxId(wuid);

    // Get the user token from the server
    fetchTokenInstance.onmessage = (message) => {
      if (message) {
        fetchTokenInstance.postMessage('close');

        setUserToken(message.data.token);
      }
    };

    fetchTokenInstance.postMessage([`${enviromentVariables().backend}/api/v1/accounts`, wuid, contractAddress]);

    getKyaniteBalance();
    getUserInfo();
    //getComponents();
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (!ethereum) {
    metaMaskInstalledText = (
      <div style={{fontSize: '1.5em'}}>
        <img src={redX} className="pulsingInfinite" alt="" />You do not have MetaMask installed. Please
        <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" rel="noreferrer" target="_blank">click here</a>
        to install MetaMask.</div>
      );
  } else {
    metaMaskInstalledText = <div style={{fontSize: '1.5em'}}><img src={smallLogo} className="pulsingInfinite" alt="" /> MetaMask is installed</div>;

    if (typeof ethereum._state !== 'undefined') {
      if (ethereum._state.accounts !== null) {
        if (ethereum._state.accounts.length > 0) {
          metaMaskConnectedText = <div style={{fontSize: '1.5em'}}><img src={smallLogo} className="pulsingInfinite" alt="" /> MetaMask is connected</div>;

          getUserBalance(ethereum._state.accounts[0]);

          if (userBalance * starShipPrice >= 0.0) {
            starshipBalanceText = <div style={{fontSize: '1.5em'}}><img src={smallLogo} className="pulsingInfinite" alt="" /> $STARSHIP balance of at least $0 USD. Your balance: ${userBalance * starShipPrice}</div>;
            waxAccountText = (
              <div style={{fontSize: '1.5em'}}>
                <hr />
                <label for="txtWaxAccount">Link your WAX account: </label>
                <input type="text" id="txtWaxAccount" />
                <div style={{textAlign: 'center', marginTop: '1em'}}>
                  <button className="bigColorfulButton" onClick={async () => {
                    let waxaccount = document.getElementById('txtWaxAccount').value;
                    let provider = new ethers.providers.Web3Provider(ethereum);
                    let signer = provider.getSigner();
                    let signature = await signer.signMessage(waxaccount);
                    let wallet = await signer.getAddress();

                    // post to the server to create account
                    fetch(`${enviromentVariables().backend}/api/v1/accounts`, {
                      method: 'POST',
                      mode: 'cors',
                      headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'JSON'
                      },
                      body: JSON.stringify({
                        waxaccount: waxaccount,
                        signature: signature,
                        wallet: wallet.toLowerCase()
                      })
                    }).then(response => {
                      if (!response.ok) {
                        toast.error(response.statusText,{
                          position: toast.POSITION.TOP_CENTER
                        });
                        throw Error(response.statusText);
                      }
                      return response.json()
                    }).then(data => {
                      toast.success('Signed Up! You may now log in with your WAX account.',{
                        position: toast.POSITION.TOP_CENTER
                      });
                    }).catch(error => {
                      console.error(error);
                      toast.error(error,{
                        position: toast.POSITION.TOP_CENTER
                      });
                    });
                  }}>Sign Up</button>
                </div>
              </div>
            );
          } else {
            starshipBalanceText = (
              <div style={{fontSize: '1.5em'}}>
                <p><img src={redX} className="pulsingInfinite" alt="" /> $STARSHIP balance of at least 5. Your balance: {userBalance}</p>
                <p>In order to receive starships to play in the StarShip P2E NFT Game, you will need to hold $STARSHIP token in
               a MetaMask wallet. <a href="https://medium.com/@deploystarship/how-to-buy-starship-token-using-metamask-8be091ee3c20" rel="noreferrer" target="_blank">Click here</a> for
               detailed instructions on how to do this.</p>
              </div>);
          }
        } else {
          metaMaskConnectedText = <div style={{fontSize: '1.5em'}}><img src={redX} className="pulsingInfinite" alt="" /> MetaMask is not connected<br /><button onClick={() => {
            connectWallet();
          }}>Connect Meta Mask</button></div>;
        }
      }
    }
  }

  async function anchorLogin() {
    try {
      let i;
      // let hostname = window.location.hostname;

      // if (hostname === 'play.deploystarship.com') {
      // if (isProd()) {
      //   i = await link.login('starshipgame');
      // } else {
      //   i = await link.login('starshipnfts');
      // }

    i = await link.login(collectionName);

      setIdentity(i);

      const { session } = i;
      setAnchorWax(true);

      setUser(`${session.auth.actor}`);
      getFleet(i);
      getKyaniteBalance();
    } catch(ex) {
      console.warn(ex.message);
    }
    getUpgradeData();
  }

  // Normal login. Triggers a popup for non-whitelisted dapps
  async function waxLogin() {
    try {
      // if autologged in, this simply returns the userAccount w/no popup
      var result = await wax.login();
      setCloudWallet(true);

      (async () => {
        setUser(wax.userAccount);
        getFleet(null);
        getKyaniteBalance();
      })(this);
    } catch (e) {
      console.error(e.message);
    }
    getUpgradeData();
  }

  async function logout() {
    setWax(new waxjs.WaxJS({
      // rpcEndpoint: enviromentVariables().nodeUrl,
      rpcEndpoint: sessionEndpoint,
      tryAutoLogin: false
    }));

    setAnchorWax(false);
  }

  async function getRentalShipData(id) {
    // return await fetch(`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, {
    return await fetch(`${sessionEndpoint}/v1/chain/get_table_rows`, {
      method: 'POST',
      body: JSON.stringify(
        {
          json: true,
          code: contractAddress,
          table: 'starshipdata',
          scope: 'starshipgame',
          'lower_bound': id,
          'upper_bound': id,
          reverse: false,
          'show_payer': false
        }),
      mode: 'cors',
      headers: {
        'Content-Type': 'text/plain',
        'X-Requested-With': 'JSON'
      }
    });
  }

  async function getRentedShips() {
    let player = '';

    if (anchorWax) {
      let { session } = identity;
      player = session.auth.actor;
    } else {
      player = wax.userAccount;
    }

    // fetch(`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, {
    fetch(`${sessionEndpoint}/v1/chain/get_table_rows`, {
      method: 'POST',
      body: JSON.stringify(
        {
          json: true,
          code: contractAddress,
          table: 'rentalships',
          scope: 'starshipgame',
          'lower_bound': player,
          'upper_bound': player,
          'index_position': '2',
          'key_type': 'name',
          reverse: false,
          'show_payer': false,
          limit: 1000
        }),
      mode: 'cors',
      headers: {
        'Content-Type': 'text/plain',
        'X-Requested-With': 'JSON'
      }
    })
    .then(response => response.json())
    .then(async (data) => {
      let rentalShips = [];
      let responseData = data;

      if (typeof responseData.rows != 'undefined') {
        let shipResponse;

        for (let i=0; i<responseData.rows.length; i++) {
          shipResponse = await getRentalShipData(responseData.rows[i].key);
          let shipData = await shipResponse.json();

          if (data.rows[i].rental_end > (new Date().getTime() / 1000)) {
            rentalShips.push(shipData.rows[0]);
          }
        }

        setRentedShips(rentalShips);
      }
    });
  }

  async function getFleet(anchorInstance) {
    let fetchFleetInstance = new WorkerBuilder(FetchFleetWorker);
    fetchFleetInstance.onmessage = (message) => {
      if (message) {
        fetchFleetInstance.postMessage('close');

        if (typeof message.data != 'undefined') {
          if ((typeof message.data.rows != 'undefined') && message.data.rows.length > 0) {
            setFleet(message.data.rows);
            console.debug('Fleet set!', message.data.rows);
            // console.table(message.data.rows);
          }
        }
      }
    };

    let waxUserAccount = '';
    if (typeof wax.userAccount === 'undefined') {
      if (typeof anchorInstance === 'undefined') {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      } else {
        let { session } = anchorInstance;
        waxUserAccount = `${session.auth.actor}`;
      }
    } else {
      waxUserAccount = wax.userAccount;
    }

    if (typeof waxUserAccount !== 'undefined') {
      if (waxUserAccount !== '') {
        // fetchFleetInstance.postMessage([`${proxy}${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
        fetchFleetInstance.postMessage([`${proxy}${sessionEndpoint}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
      }
    }
  }

  function getUserInfo() {
    let waxUserAccount = '';
    if (typeof wax.userAccount === 'undefined') {
      if (typeof anchorInstance === 'undefined') {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      } else {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      }
    } else {
      waxUserAccount = wax.userAccount;
    }

    let fetchPlayerInfoInstance = new WorkerBuilder(FetchPlayerInfoWorker);
    fetchPlayerInfoInstance.onmessage = (message) => {
      if (message) {
        fetchPlayerInfoInstance.postMessage('close');

        if (typeof message.data != 'undefined') {
          if ((typeof message.data.rows != 'undefined') && message.data.rows.length > 0) {
            setStakedBalance(parseFloat(message.data.rows[0].kyanite).toFixed(4) + ' Kyanite');
            setUserBalance(parseFloat(message.data.rows[0].starship_balance).toFixed(4));
            setLastClaim(message.data.rows[0].last_claim);
          }
        }

        getStationData();
        
      }
    };

    if (waxUserAccount !== '') {
      // fetchPlayerInfoInstance.postMessage([`${proxy}${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
      fetchPlayerInfoInstance.postMessage([`${proxy}${sessionEndpoint}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
    }
  }

  function getStationData() {
    let fetchStationDataInstance = new WorkerBuilder(FetchStationData);
    fetchStationDataInstance.onmessage = (message) => {
      if (message) {
        fetchStationDataInstance.postMessage('close');
        setStationData(message.data.rows);
      }
    };

    // fetchStationDataInstance.postMessage([`${proxy}${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, contractAddress]);
    fetchStationDataInstance.postMessage([`${proxy}${sessionEndpoint}/v1/chain/get_table_rows`, contractAddress]);
  }

  async function getUpgradeData() {
    console.log("getupgradedata worked")
    let plntupgconf = await wax.rpc.get_table_rows({
      json: true,
      code: contractAddress,
      scope: contractAddress,
      table: 'plntupgconf',
      limit: 1000,
    });

    let plntpoolconf = await wax.rpc.get_table_rows({
      json: true,
      code: contractAddress,
      scope: contractAddress,
      table: 'plntpoolconf',
      limit: 1000,
    });


    setPlanetPoolConfig(plntpoolconf.rows)
    setPlanetUpgConfig(plntupgconf.rows)
  }
  

  function getShipData(id) {
    console.debug('Ship ID:', id);
    if (id !== '' && parseInt(id, 10) !== 0) {
      if (!fetchingShipData) {
        let fetchShipDataInstance = new WorkerBuilder(FetchEquippedPartsWorker);
        fetchShipDataInstance.onmessage = (message) => {
          if (message) {

            if (typeof message.data !== 'undefined') {
              setShipData(message.data.rows[0]);
            }

            fetchShipDataInstance.postMessage('close');
            setFetchingShipData(false);
          }
        };

        // fetchShipDataInstance.postMessage([`${proxy}${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, id, contractAddress]);
        fetchShipDataInstance.postMessage([`${proxy}${sessionEndpoint}/v1/chain/get_table_rows`, id, contractAddress]);
        setFetchingShipData(true);
      }
    } else {
      setShipData({parts: []});
    }
  }

  async function getComponents() {
    let waxUserAccount = '';
    if (typeof wax.userAccount === 'undefined') {
      if (typeof anchorInstance === 'undefined') {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      } else {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      }
    } else {
      waxUserAccount = wax.userAccount;
    }

    console.debug('Getting components for: ' + waxUserAccount);



      let player_inventory = [];
      for (let i = 1; i < 100; i++) {
        let returned = await expApi.getAssets({
          owner: waxUserAccount,
          collection_name: enviromentVariables().collectionName,
          limit: 1000,
          page: i,
        });
        player_inventory = player_inventory.concat(returned);
        if (returned.length == 1000) {
          setTimeout(() => {
            checkRewards();
          }, 250);
          continue;
        } else {
          break;
        }
      }
      console.log("Batch player's inventory")
      console.log(player_inventory);
      
      setUserAssets(player_inventory);
      let components = filterToComponents(player_inventory);
      setComponents(components);
      

      
      /*
    if (!fetchingComponents) {
      let fetchComponentsInstance = new WorkerBuilder(FetchPlayerAssetsWorker);

      fetchComponentsInstance.onmessage = (message) => {
        if (message) {
          fetchComponentsInstance.postMessage('close');
          let components = filterToComponents(message.data.data);
          console.log("OLD PLAYER INVENTORY")
          console.log(message.data.data);
          setComponents(components);
          setFetchingComponents(false);
          setUserAssets(message.data.data);
        }
      };

      if (waxUserAccount !== '') {
        console.debug(`Getting parts for ${waxUserAccount}`);
        //Getting all assets beside only the components
        //And filtering the components while sending to state above for holding the old usage.
        //fetchComponentsInstance.postMessage([`https://${enviromentVariables().atomichub}/atomicassets/v1/assets/?owner=${waxUserAccount}&collection_name=${enviromentVariables().collectionName}&schema_name=component&sort=rdata&limit=1000`]);
        fetchComponentsInstance.postMessage([`https://${enviromentVariables().atomichub}/atomicassets/v1/assets/?owner=${waxUserAccount}&collection_name=${enviromentVariables().collectionName}&sort=rdata&limit=1000`]);
        setFetchingComponents(true);

      }
    }
    */
  }

  function filterToComponents(assets) {
    let components = [];
    assets.forEach((asset) => {
      if (asset.schema.schema_name === 'component') {
        components.push(asset);
      }
    });
    return components;
  }

  function getKyaniteBalance() {
    let waxUserAccount = '';
    if (typeof wax.userAccount === 'undefined') {
      if (typeof anchorInstance === 'undefined') {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      } else {
        let { session } = identity;
        waxUserAccount = `${session.auth.actor}`;
      }
    } else {
      waxUserAccount = wax.userAccount;
    }

    if (!fetchingKyaniteBalance) {
      let fetchBalanceInstance = new WorkerBuilder(FetchBalanceWorker);

      fetchBalanceInstance.onmessage = (message) => {
        if (message) {
          fetchBalanceInstance.postMessage('close');

          if (typeof message.data !== 'undefined') {
            if (typeof message.data.rows !== 'undefined' && message.data.rows.length > 0) {
              setAvailableBalance(parseFloat(message.data.rows[0].balance).toFixed(4));
            }
          }

          setFetchingKyaniteBalance(false);
        }
      };

      if (waxUserAccount !== '') {
        console.debug(`Getting kyanite balance for ${waxUserAccount}`);
        // fetchBalanceInstance.postMessage([`${proxy}${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
        fetchBalanceInstance.postMessage([`${proxy}${sessionEndpoint}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
        setFetchingKyaniteBalance(true);
      }
    }
  }

  // This switch case statement determines the content of the modal
  switch(activeModal) {
    case 'create':
      modalContent = (<div><h2 ref={(_subtitle) => (subtitle = _subtitle)}>Join Starship</h2><br />
        {metaMaskInstalledText}
        {metaMaskConnectedText}
        {starshipBalanceText}
        {waxAccountText}
      </div>);
      break;
    case 'login':
      if (isProd()) {
        modalContent = (
          <div>
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Login</h2><br />
            <div className="button" onClick={async () => {
              await waxLogin();
              closeModal();
            }}>
              <p>WAX Cloud Wallet</p>
            </div>
            <div className="button" style={{marginTop: '1em'}} onClick={async () => {
              await anchorLogin();
              closeModal();
            }}>
              <p>Anchor</p>
            </div>
          </div>
        );
      } else {
        modalContent = (
          <div>
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Login</h2><br />
            <div className="button" style={{marginTop: '1em'}} onClick={async () => {
              await anchorLogin();
              closeModal();
            }}>
              <p>Anchor</p>
            </div>
          </div>
        );
      }

      break;
  }

  let customStyles = {
    content: {
      top: '50%',
      left: '50%',
      borderWidth: '2px',
      borderColor: 'rgba(23, 22, 41, 1)',
      backgroundColor: 'rgba(33, 30, 99, 0.5)',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  let screenContent;

  let refreshRewardsButton = (
    472389
  )

  let claimRewardDroidFrameStyle = {
    //centered, small framed header text area on the bottom of div
    position: 'absolute',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(70, 82, 144, 0.5)',
    borderRadius: '0.5em',
    padding: '0.3em',
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '0.8em',
    fontWeight: 'bold',
    textShadow: '0 0 0.5em black',

    // make it aligned to the left with the background
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  }

  let checkRewardDroidFrameStyle = {
    //centered, small framed header text area on the bottom of div
    position: 'absolute',
    bottom: '0',
    left: '0',


    backgroundColor: 'rgba(70, 82, 144, 0.5)',
    borderRadius: '0.5em',
    padding: '0.3em',
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '0.8em',
    fontWeight: 'bold',
    textShadow: '0 0 0.5em black',

    // make it aligned to the left with the background
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    

    
  }

  let rewardCounterStyle = {
    //round counter on top of droid with the same colors of checkRewardDroidFrameStyle
    position: 'absolute',
    top: '0',
    left: '0',
    width: '1.5em',
    height: '1.5em',
    borderRadius: '50%',
    backgroundColor: 'rgba(70, 82, 144, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '0.8em',
    fontWeight: 'bold',
    textShadow: '0 0 0.5em black',

  }

  if (rewards) {
    rewardsIndicator = (
      <div className="rewardsIndicator"  onClick={async () => {
        let waxUserAccount = '';
        let player = '';

        if (typeof wax.userAccount === 'undefined') {
          let { session } = identity;
          waxUserAccount = `${session.auth.actor}`;
          player = `${session.auth.actor}`;
        } else {
          waxUserAccount = wax.userAccount;
          player = wax.userAccount;
        }

        let actions = [
          {
            account: contractAddress,
            name: 'claimrewards',
            authorization: [{
              actor: waxUserAccount,
              permission: 'active',
            }],
            data: {
              player_id: player
            },
          }
        ]

        try {
          let result;
          if (typeof wax.userAccount !== 'undefined') {
            result = await wax.api.transact({
              actions: actions
            }, {
              blocksBehind: 3,
              expireSeconds: 1200,
            });
          } else {
            let { session } = identity;
            result = await session.transact({
              actions: actions
            }, {
              blocksBehind: 3,
              expireSeconds: 1200,
            })
          }

          toast.success('Rewards claimed!',{
            position: toast.POSITION.TOP_CENTER
          });

          // Wait five seconds and update the fleet
          setTimeout(() => {
            checkRewards();
          }, 5000);
        } catch(ex) {
          toast.error(ex.toString(),{
            position: toast.POSITION.TOP_CENTER
          });
        }
      }}>
        <div style={rewardCounterStyle} >{rewardCount}</div>
        <img src={robot} alt="Rewards Found!" title="Rewards Found!" />
      </div>
    );
  }



  if (!rewards) {
    
    rewardsIndicator = (
      <div className="rewardsIndicator" style={{animation:"none"}} onClick={async () => {
        await checkRewards();
      }}>
       
        <div style={rewardCounterStyle}>&#x21bb;</div>
        <img src={robot} alt="Rewards Found!" title="Rewards Found!" />
      </div>
    );
  }



  if (typeof wax.userAccount !== 'undefined' || anchorWax) {
    switch(gameScreen) {
      case 0:
        screenContent = (
          <div className="divSpaceStation">
            {rewardsIndicator}
            <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
            <Station getFleet={getFleet} userBalance={userBalance} starShipPrice={starShipPrice} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} fleet={fleet} wax={wax} setGameScreen={setGameScreen} toast={toast} stationData={stationData} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          </div>
        );
        break;
      case 1:
        screenContent = (
          <div className="divHangar">
            {rewardsIndicator}
            <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
            <Hangar userBalance={userBalance} starShipPrice={starShipPrice} getEquippedComponents={getEquippedComponents} equippedParts={equippedParts} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} fleet={fleet} availableBalance={availableBalance} stakedBalance={stakedBalance} setGameScreen={setGameScreen} toast={toast} getComponents={getComponents} wax={wax} waxId={wax.userAccount} getShipData={getShipData} starShips={starShips} shipData={shipData} components={components} />
          </div>
        );
        break;
      case 2:
        screenContent = (
        <div className="divMissions">
          {rewardsIndicator}
          <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          <Missions userBalance={userBalance} starShipPrice={starShipPrice} rentedShips={rentedShips} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} destinationPlanet={destinationPlanet} selectedStarShip={selectedStarShip} setSelectedStarShip={setSelectedStarShip} getFleet={getFleet} fleet={fleet} setGameScreen={setGameScreen} stakedBalance={stakedBalance} toast={toast} wax={wax} starShips={starShips} availableBalance={availableBalance} getShipData={getShipData} shipData={shipData} planetPoolConfig={planetPoolConfig} planetUpgConfig={planetUpgConfig} userAssets={userAssets} getComponents={getComponents}/>
        </div>
      );
      break;
      case 3:
        screenContent = (
        <div className="divSpacePlanets">
          {rewardsIndicator}
          <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          <Planets userBalance={userBalance} starShipPrice={starShipPrice} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} setGameScreen={setGameScreen} toast={toast} wax={wax} availableBalance={availableBalance} stakedBalance={stakedBalance} />
        </div>
      );
      break;
      case 4:
        screenContent = (
        <div className="divChat">
          {rewardsIndicator}
          <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          <div>
            <h1 className="screenTitle">Chat</h1>
          </div>
        </div>
      );
      break;
      case 5:
        screenContent = (
        <div className="divAction">
          {rewardsIndicator}
          <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          <div>
            <h1 className="screenTitle">Action</h1>
          </div>
        </div>
        );
        break;
      case 6:
        screenContent = (
        <div className="divStakeWithdraw">
          {rewardsIndicator}
          <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          <StakeWithdraw userBalance={userBalance} starShipPrice={starShipPrice} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} setGameScreen={setGameScreen} toast={toast} wax={wax} availableBalance={availableBalance} stakedBalance={stakedBalance} />
        </div>
        );
        break;
      case 7:
        screenContent = (
          <div className="divRentals">
            {rewardsIndicator}
            <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
            <Rental userBalance={userBalance} starShipPrice={starShipPrice} getShipData={getShipData} shipData={shipData} getFleet={getFleet} fleet={fleet} selectedStarShip={selectedStarShip} setSelectedStarShip={setSelectedStarShip} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} setGameScreen={setGameScreen} toast={toast} wax={wax} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          </div>
          );
        break;
        case 8:
          screenContent = (
            <div className="divLeaderBoards">
              {rewardsIndicator}
              <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
              <Leaderboards userBalance={userBalance} starShipPrice={starShipPrice} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} setGameScreen={setGameScreen} toast={toast} wax={wax} availableBalance={availableBalance} stakedBalance={stakedBalance} />
            </div>
            );
            break;
        case 9:
          screenContent = (
            <div className="divShop">
              {rewardsIndicator}
              <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
              <Shop userBalance={userBalance} starShipPrice={starShipPrice} identity={identity} cloudWallet={cloudWallet} anchorWax={anchorWax} setGameScreen={setGameScreen} toast={toast} wax={wax} availableBalance={availableBalance} stakedBalance={stakedBalance} />
            </div>
            );
          break;
      default:
        screenContent = (
        <div className="divShouldNotExist">
          {rewardsIndicator}
          <MobileMenu starShipPrice={starShipPrice} setGameScreen={setGameScreen} waxId={wax.userAccount} logout={logout} availableBalance={availableBalance} stakedBalance={stakedBalance} />
          <div>
            <h1 className="screenTitle">Should Never Get Here</h1>
          </div>
        </div>
        );
        break;
    }
  } else {
    screenContent = (
      <div className="divLogin">
        <BrowserView>
          <video autoPlay loop muted id="videoBackground">
            <source src="background_login.mp4" type="video/mp4" />
          </video>
        </BrowserView>

        <div className="divMainAction">
          <div className="button" onClick={() => {
            setActiveModal('create');
            openModal();
          }}>
            <p>Create Account</p>
          </div>
          <div className="button" onClick={() => {
            setActiveModal('login');
            openModal();
          }} >
            <p>Login</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      {screenContent}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Join Starship">

        <div className="modalContent">
          {modalContent}
        </div>
        <div className="modalButtons">
          <button  onClick={closeModal}>close</button>
        </div>
      </Modal>
    </div>
  );
}

export default App;
