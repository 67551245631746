/* eslint-disable */
// fetch.worker.js
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (e) => {
    if (e.data === 'close') {
      self.close();
    } else {
      let url = e.data[0];

      fetch(url)
      .then(response => response.json())
      .then(data => {
        postMessage(data);
      });
    }
  };
};
