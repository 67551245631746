import React, { useState } from 'react';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import {contractAddress} from '../../helpers';
import Launcher from '../launcher';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import Adarea1 from '../adarea1';

export default function StakeWithdraw(props) {
  const setGameScreen = props.setGameScreen;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const availableBalance = props.availableBalance;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const toast = props.toast;
  const [amount, setAmount] = useState();

  async function stakeKyanite() {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;;
    }

    let actions = [
      {
        account: 'kyanitelpnft',
        name: 'transfer',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          from: player,
          to: contractAddress,
          quantity: parseFloat(amount).toFixed(4) + ' KYANITE',
          memo: 'KYANITE Stake'
        },
      }
    ];

    try {
      //let result;
      if (cloudWallet) {
        await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Kyanite has been successfully staked.',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function withdrawKyanite() {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'withdraw',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          amount: amount
        },
      }
    ];

    try {
      //let result;
      if (cloudWallet) {
        await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Kyanite has been successfully withdrawn.',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  return (
    <div>
      <div className="divStakeWithdrawDesktop">
        <div className="divDesktopBackground">
          <img src="planetsScreen.jpg" className="desktopBackground" />
        </div>
        <div className="divDesktopContent">
          <div className="divStakeWithdraw">
            <div>
              <h3>Available Kyanite in Wallet: {availableBalance}</h3>
            </div>

            <div>
              <h3>Staked Kyanite: {stakedBalance.split(' ')[0]}</h3>
            </div>

            <div>
              <p>All Kyanite withdrawals are subject to a 10% tax.</p>
            </div>

            <div>
            <label htmlFor="txtNumber">Enter Amount:</label><br />
              <input type="number" id="txtNumber" onChange={(event) => {
                setAmount(event.target.value);
              }} />
            </div>

            <div>
              <button onClick={async () => {
                withdrawKyanite();
              }}>Withdraw</button>&nbsp;
              <button onClick={async () => {
                stakeKyanite();
              }}>Stake</button>
            </div>
          </div>
        </div>
        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <div className="desktopStationButtons">
          </div>
          <img src={controlPanelBackground} alt="" />
          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
            let waxUserAccount = '';
            let player = '';
            if (anchorWax) {
              let { session } = identity;
              waxUserAccount = session.auth.actor;
              player = session.auth.actor;
            } else {
              waxUserAccount = wax.userAccount;
              player = wax.userAccount;
            }

            let actions = [
              {
                account: contractAddress,
                name: 'claim',
                authorization: [{
                  actor: waxUserAccount,
                  permission: 'active',
                }],
                data: {
                  player: player
                },
              }
            ];

            try {
              //let result;
              if (cloudWallet) {
                await wax.api.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                });
              } else {
                let { session } = identity;
                await session.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                })
              }

              toast.success('Kyanite Claimed!',{
                position: toast.POSITION.TOP_CENTER
              });
            } catch(ex) {
              toast.error(ex,{
                position: toast.POSITION.TOP_CENTER
              });
            }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>
      </div>

      <div className="divStakeWithdrawMobile">
        <h1 className="screenTitle">Stake \ Withdraw</h1>
        <div style={{marginTop: '3em'}}>
          <div>
            <h3>Available Kyanite in Wallet: {availableBalance}</h3>
          </div>

          <div>
            <h3>Staked Kyanite: {stakedBalance.split(' ')[0]}</h3>
          </div>

          <div>
            <p>All Kyanite withdrawls are subject to a 10% tax.</p>
          </div>

          <div>
          <label htmlFor="txtNumber">Enter Amount:</label><br />
            <input type="number" id="txtNumber" onChange={(event) => {
              setAmount(event.target.value);
            }} />
          </div>

          <div>
            <button onClick={async () => {
              withdrawKyanite();
            }}>Withdraw</button>&nbsp;
            <button onClick={async () => {
              stakeKyanite();
            }}>Stake</button>
          </div>
        </div>
      </div>
    </div>
  );
}
