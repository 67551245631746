import React, { useState, useEffect  } from 'react';
import {
	convertUnixTime,
	fancyTimeFormat,
	getMinDiff,
	spaceStation,
	toTitleCase,
	getEndPoints,
	spaceStationInfo,
	sessionEndpoint, contractAddress
} from '../../../helpers';
import Modal from 'react-modal';
import FetchEquippedPartsWorker from '../../../workers/fetchEquippedParts.worker.js';
import FetchShipBalance from '../../../workers/fetchShipBalance.worker.js';
import FetchPlanetsForMission from '../../../workers/fetchPlanetsForMission.worker.js';
import FetchDestinationPlanet from '../../../workers/fetchDestinationPlanet.worker.js';
import WorkerBuilder from '../../../workers/worker-builder';
import shipCommonImage from '../../../images/shipCommonSmall.png';
import shipUncommonImage from '../../../images/shipUncommonSmall.png';
import shipRareImage from '../../../images/shipRareSmall.png';
import shipEpicImage from '../../../images/shipEpicSmall.png';
import shipLegendaryImage from '../../../images/shipLegendarySmall.png';
import shipMythicImage from '../../../images/shipMythicSmall.png';
import shipFounderImage from '../../../images/shipFounderSmall.png';
import radarImage from '../../../images/radar.png';
import gasPlanet from '../../../images/gas.png';
import gasDestinationSmall from '../../../images/gasSmall.jpg';
import crystalPlanet from '../../../images/crystal.png';
import crystalDestinationSmall from '../../../images/crystalSmall.jpg';
import greenlandPlanet from '../../../images/greenland.png';
import greenlandDestinationSmall from '../../../images/greenlandSmall.jpg';
import icePlanet from '../../../images/ice.png';
import iceDestinationSmall from '../../../images/iceSmall.jpg';
import desertPlanet from '../../../images/desert.png';
import desertDestinationSmall from '../../../images/desertSmall.jpg';
import swampPlanet from '../../../images/swamp.png';
import swampDestinationSmall from '../../../images/swampSmall.jpg';
import oceanPlanet from '../../../images/ocean.png';
import oceanDestinationSmall from '../../../images/oceanSmall.jpg';
import magmaPlanet from '../../../images/magma.png';
import magmaDestinationSmall from '../../../images/magmaSmall.jpg';
import paradisePlanet from '../../../images/paradise.png';
import paradiseDestinationSmall from '../../../images/paradiseSmall.jpg';
import metallicPlanet from '../../../images/metallic.png';
import metallicDestinationSmall from '../../../images/metalSmall.jpg';
import radarSmall from '../../../images/radarSmall.png';
import hangarSmall from '../../../images/hangarSmall.jpg';
import starfieldSimulation from '../../../images/StarfieldSimulation.gif';
import Select from 'react-select';

export default function ShipData(props) {
  const starShipData = props.starShipData;
  const toast = props.toast;
  const starShip = props.starShip;
  const stakedBalance = props.stakedBalance;
  const claimperiod = props.claimperiod;
  const claimplanetc = props.claimplanetc;
  const interdistanc = props.interdistanc;
  const planetcommis = props.planetcommis;
  const planetreward = props.planetreward;
  const sectorsize = props.sectorsize;
  const spacecommis = props.spacecommis;
  const rewardMod = props.rewardMod;
  const anchorWax = props.anchorWax;
  const wax = props.wax;
  const identity = props.identity;
  const cloudWallet = props.cloudWallet;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [fetchingShipData, setFetchingShipData] = useState(false);
  const [fetchingPlanetData, setFetchingPlanetData] = useState(false);
  const [fetchingDestinationPlanetData, setFetchingDestinationPlanetData] = useState(false);
  const [modalContentState, setModalContentState] = useState(0);
  const [selectedSpaceStation, setSelectedSpaceStation] = useState('');
  const [selectedQuadrant, setSelectedQuadrant] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedSystem, setSelectedSystem] = useState('');
  const [planets, setPlanets] = useState([]);
  const [planetData, setPlanetData] = useState([]);
  const [selectedPlanet, setSelectedPlanet] = useState(0);
  const [selectedPlanetPrice, setSelectedPlanetPrice] = useState(0);
  const [destinationPlanet, setDestinationPlanet] = useState(null);

  let subtitle;
  let modalContent;
  let container;

  let customStyles = {
    content: {
      top: '50%',
      left: '50%',
      borderWidth: '2px',
      borderColor: 'rgba(23, 22, 41, 1)',
      backgroundColor: 'rgba(33, 30, 99, 0.5)',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 300
    },
  };

  const customOptionStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'red' : 'black',
      padding: 20,
    })
  };

  let today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  tomorrow.setHours(0,0,0,0);

  async function sendToSpaceStation(starShip) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'move2station',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip,
          spacestation: selectedSpaceStation
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('En route to space station!',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function launchMission(starShip) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'move2planet',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip,
          planet: selectedPlanet
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Launched on Mission!',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function claim() {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'claim',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Kyanite claimed!',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function getDestinationPlanet(id) {
      if (!fetchingDestinationPlanetData) {
        let fetchDestinationPlanetInstance = new WorkerBuilder(FetchDestinationPlanet);
        fetchDestinationPlanetInstance.onmessage = (message) => {
          if (message) {
            fetchDestinationPlanetInstance.postMessage('close');

            if (typeof message.data !== 'undefined') {
              if (typeof message.data.rows !== 'undefined') {
                setDestinationPlanet(message.data.rows[0]);
              }
            }

            setFetchingPlanetData(false);
          }
        };

        // fetchDestinationPlanetInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, id, contractAddress]);
        fetchDestinationPlanetInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, id, contractAddress]);
        setFetchingDestinationPlanetData(true);
      }
  }

  function getMissionPlanets(id) {
    if (!fetchingPlanetData) {
      let fetchPlanetDataInstance = new WorkerBuilder(FetchPlanetsForMission);
      fetchPlanetDataInstance.onmessage = (message) => {
        if (message) {
          fetchPlanetDataInstance.postMessage('close');

          if (typeof message.data !== 'undefined') {
            if (typeof message.data.rows !== 'undefined') {
              let pd = [];

              for (let i=0; i<message.data.rows.length; i++) {
                pd[i] = message.data.rows[i];
                pd[i].claimPrice = parseFloat(claimplanetc / 100).toFixed(2) * parseFloat(pd[i].max_pool).toFixed(2);
              }

              setPlanetData(pd);
            }
          }

          setFetchingPlanetData(false);
        }
      };

      // fetchPlanetDataInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, id, contractAddress]);
      fetchPlanetDataInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, id, contractAddress]);
      setFetchingPlanetData(true);
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getPlanets();
  }, [selectedQuadrant, selectedSector, selectedSystem]);

  async function minePlanet(wax, starship) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'collect',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Planet mined! Returning to space station.',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function claimPlanet(starShip, planetid) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'claimplanet',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip,
          planetid: planetid
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Planet claimed!',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function getPlanets() {
    if (selectedSpaceStation !== '' &&
        selectedQuadrant !== '' &&
        selectedSector !== '' &&
        selectedSystem !== ''
    ) {
      let spacestation = selectedSpaceStation * 16384;
      let quadrant = selectedQuadrant * 4096 ;
      let sector = selectedSector * 128;
      let system = selectedSystem * 4;
      let planetIds = [];
      planetIds[0] = spacestation + quadrant + sector + system;
      planetIds[1] = planetIds[0] + 1;
      planetIds[2] = planetIds[0] + 2;
      planetIds[3] = planetIds[0] + 3;
      setPlanets(planetIds);
      getMissionPlanets(planetIds[0]);
    } else {
      setPlanets([]);
    }
  }

  switch (modalContentState) {
    case 0:
      const quadrantOptions = [
        { value: '0', label: 'North' },
        { value: '3', label: 'South' },
        { value: '2', label: 'East' },
        { value: '1', label: 'West' }
        ];

      let numbers = [];

      for (var i=0; i<25; i++) {
        numbers.push(i);
      }

      const sectorOptions = numbers.map((number, i) => {
        return { value: number, label: number };
      });

      const systemOptions = numbers.map((number, i) => {
        return { value: number, label: number };
      });

        modalContent = (
          <div style={{width: '100%'}}>
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Select Mission</h2>

            <div className="divMissionSelect">
              Quadrant<br />
              <Select styles={customOptionStyles} onChange={async (selectedOption) => {
                setSelectedQuadrant(selectedOption.value);
              }} defaultValue={selectedQuadrant} options={quadrantOptions} placeholder="Select Quadrant" /><br />
              Sector<br />
              <Select styles={customOptionStyles} onChange={async (selectedOption) => {
                setSelectedSector(selectedOption.value);
              }} defaultValue={selectedSector} options={sectorOptions} placeholder="Select Sector" />
              Distance: {(parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)} Parsecs<br />
              Time to travel: {fancyTimeFormat(((parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)) * starShipData.speed)}<br /><br />
              System<br />
              <Select styles={customOptionStyles} onChange={async (selectedOption) => {
                setSelectedSystem(selectedOption.value);
              }} defaultValue={selectedSystem} options={systemOptions} placeholder="Select System" /><br />
              Select Planet<br />
              {planets.map((planet, i) => {
                let planetCards = [];

                if (planetData.length > 0) {
                  for (let j=0; j<planetData.length; j++) {
                    let planetImage;
                    let claimPrice = '';

                    if (planetData[j].owner === contractAddress) {
                      claimPrice = `Claim Price: ${(parseFloat(planetData[j].max_pool) * parseFloat(claimplanetc / 100)).toFixed(4)}`;
                    }

                    switch(planetData[j].type.toUpperCase()) {
                      case ('CRYSTAL'):
                        planetImage = <img src={crystalPlanet} alt="Crystal" />
                        break;
                      case ('GAS'):
                        planetImage = <img src={gasPlanet} alt="Gas" />
                        break;
                      case ('DESERT'):
                        planetImage = <img src={desertPlanet} alt="Desert" />
                        break;
                      case ('GLACIER'):
                        planetImage = <img src={icePlanet} alt="Ice" />
                        break;
                      case ('MAGMA'):
                        planetImage = <img src={magmaPlanet} alt="Magma" />
                        break;
                      case ('SWAMP'):
                        planetImage = <img src={swampPlanet} alt="Swamp" />
                        break;
                      case ('OCEAN'):
                        planetImage = <img src={oceanPlanet} alt="Ocean" />
                        break;
                      case ('GRASSLAND'):
                        planetImage = <img src={greenlandPlanet} alt="Grassland" />
                        break;
                      case ('PARADISE'):
                        planetImage = <img src={paradisePlanet} alt="Paradise" />
                        break;
                      case ('METALLIC'):
                        planetImage = <img src={metallicPlanet} alt="Metallic" />
                        break;
                    }

                    planetCards[j] = (
                      <div className={planetData[j].id === selectedPlanet ? 'planetCardSelectedMissionSelect' : 'planetCardMissionSelect'} onClick={() => {
                        setSelectedPlanet(planetData[j].id);
                      }}>
                        <div>
                          {planetImage}
                        </div>
                        <div>
                          {toTitleCase(planetData[j].type)} {planetData[j].id}
                        </div>
                        <div>
                          {planetData[j].next_reset > today / 1000 ? parseFloat(planetData[j].pool).toFixed(0) : parseFloat(planetData[j].max_pool).toFixed(0)} / {parseFloat(planetData[j].max_pool).toFixed(0)}
                        </div>
                        <div>
                          {claimPrice}
                        </div>
                        <div>
                          Owner:<br />{planetData[j].owner === contractAddress ? 'Available' : planetData[j].owner}
                        </div>
                        <div>
                          Fuel Cost: {(((parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)) * parseFloat(starShipData.consumption)).toFixed(2)} ({(((parseFloat(sectorsize) * parseFloat(selectedSector + 1)).toFixed(2)) * parseFloat(starShipData.consumption)).toFixed(2) / 4} if ship is a rental)
                        </div>
                      </div>
                    );
                  }
                } else {
                  planetCards[0] = <span></span>;
                  planetCards[1] = <span></span>;
                  planetCards[2] = <span></span>;
                  planetCards[3] = <span></span>;
                }

                return (
                  <div key={i} style={{display: 'inline-block', width: '40%', marginLeft: '1.0em', marginRight: '1.0em', marginBottom: '0.5em'}}>
                    {planetCards[i]}
                  </div>
                );
              })}<br />
              <button onClick={() => {
                launchMission(starShip);
                closeModal();
              }}>Launch</button>
            </div>
          </div>
        );
      break;
    case 1:
      modalContent = (
        <div>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Select Space Station</h2>
          <select onChange={event => {
            if (event.target.value !== '') {
              setSelectedSpaceStation(event.target.value);
            } else {
              setSelectedSpaceStation({});
            }
          }}>
            <option value="">Select Space Station</option>
            {spaceStationInfo().map((ss, i) => {
              return (
                <option value={i} key={i}>{ss.name}</option>
              );
            })}
          </select><br />
          <button onClick={() => {
            sendToSpaceStation(starShip);
            closeModal();
          }}>Launch</button>
        </div>
      );
      break;
  }

  let statusText = '';
  let shipTypeText = '';
  let shipImage = '';
  let destinationImage = '';
  let shipBackgroundImage = '';
  let btnCollect = '';
  let btnSendToSpaceStation = '';
  let btnSendOnMission = '';
  let btnMinePlanet = '';
  let btnClaimPlanet = '';
  let currentDate = new Date().getTime();
  let timeDiff = starShipData.end*1000 - currentDate;
  let timeLeft = getMinDiff(currentDate, starShipData.end*1000);
  let timeLeftText = fancyTimeFormat(timeLeft) + ' Remaining';
  let planetText = '';

  if (starShipData.planet > 0) {
    getDestinationPlanet(starShipData.planet);

    if (timeDiff > 0) {
      statusText = 'En route to planet ' + starShipData.planet;

      shipBackgroundImage = (
        <img src={starfieldSimulation} alt="" className="starFieldImage" />
      );

      if (destinationPlanet !== null) {
        if (destinationPlanet.owner === contractAddress) {
          planetText = (
            <div>
              {toTitleCase(destinationPlanet.type)}<br />
              Owner: None<br />
              Claim Price: {(parseFloat(destinationPlanet.max_pool) * (claimplanetc / 100)).toFixed(2)}<br />
              Pool: {destinationPlanet.next_reset > today / 1000 ? parseFloat(destinationPlanet.pool).toFixed(0) : Math.floor(parseFloat(destinationPlanet.max_pool).toFixed(0) * 0.2)} / {parseFloat(destinationPlanet.max_pool).toFixed(0)}
            </div>
          );
        } else {
          planetText = (
            <div>
              {toTitleCase(destinationPlanet.type)}<br />
              Owner: {destinationPlanet.owner}<br />
              Pool: {destinationPlanet.next_reset > today / 1000 ? parseFloat(destinationPlanet.pool).toFixed(0) : parseFloat(destinationPlanet.max_pool).toFixed(0)} / {parseFloat(destinationPlanet.max_pool).toFixed(0)}
            </div>
          );
        }

        switch (destinationPlanet.type.toUpperCase()) {
          case ('DESERT'):
            destinationImage = (
              <img src={desertPlanet} alt="Desert Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('GRASSLAND'):
            destinationImage = (
              <img src={greenlandPlanet} alt="Grassland Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('METALLIC'):
            destinationImage = (
              <img src={metallicPlanet} alt="Metallic Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('CRYSTAL'):
            destinationImage = (
              <img src={crystalPlanet} alt="Crystal Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('GAS'):
            destinationImage = (
              <img src={gasPlanet} alt="Gas Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('GLACIER'):
            destinationImage = (
              <img src={icePlanet} alt="Ice Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('MAGMA'):
            destinationImage = (
              <img src={magmaPlanet} alt="Magma Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('OCEAN'):
            destinationImage = (
              <img src={oceanPlanet} alt="Ocean Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('PARADISE'):
            destinationImage = (
              <img src={paradisePlanet} alt="Paradise Planet" className="imgDestinationPlanet" />
            );
            break;
          case ('SWAMP'):
            destinationImage = (
              <img src={swampPlanet} alt="Swamp Planet" className="imgDestinationPlanet" />
            );
            break;
        }
      }
    } else {
      statusText = 'Ready to mine planet ' + starShipData.planet;

      if (destinationPlanet !== null) {
        if (destinationPlanet.owner === contractAddress) {
          planetText = (
            <div>
              {toTitleCase(destinationPlanet.type)}<br />
              Owner: None<br />
              Claim Price: {(parseFloat(destinationPlanet.max_pool) * (claimplanetc / 100)).toFixed(2)}<br />
              Pool: {destinationPlanet.next_reset > today / 1000 ? parseFloat(destinationPlanet.pool).toFixed(0) : Math.floor(parseFloat(destinationPlanet.max_pool).toFixed(0) * 0.2)} / {parseFloat(destinationPlanet.max_pool).toFixed(0)}
            </div>
          );
        } else {
          planetText = (
            <div>
              {toTitleCase(destinationPlanet.type)}<br />
              Owner: {destinationPlanet.owner}<br />
              Pool: {destinationPlanet.next_reset > today / 1000 ? parseFloat(destinationPlanet.pool).toFixed(0) : parseFloat(destinationPlanet.max_pool).toFixed(0)} / {parseFloat(destinationPlanet.max_pool).toFixed(0)}
            </div>
          );
        }

        switch (destinationPlanet.type.toUpperCase()) {
          case ('DESERT'):
            shipBackgroundImage = (
              <img src={desertPlanet} alt="Desert Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={desertDestinationSmall} alt="Desert Planet" className="imgDestination" />
            );
            break;
          case ('GRASSLAND'):
            shipBackgroundImage = (
              <img src={greenlandPlanet} alt="Grassland Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={greenlandDestinationSmall} alt="Grassland Planet" className="imgDestination" />
            );
            break;
          case ('CRYSTAL'):
            shipBackgroundImage = (
              <img src={crystalPlanet} alt="Crystal Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={crystalDestinationSmall} alt="Crystal Planet" className="imgDestination" />
            );
            break;
          case ('GAS'):
            shipBackgroundImage = (
              <img src={gasPlanet} alt="Gas Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={gasDestinationSmall} alt="Gas Planet" className="imgDestination" />
            );
            break;
          case ('GLACIER'):
            shipBackgroundImage = (
              <img src={icePlanet} alt="Ice Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={iceDestinationSmall} alt="Ice Planet" className="imgDestination" />
            );
            break;
          case ('MAGMA'):
            shipBackgroundImage = (
              <img src={magmaPlanet} alt="Magma Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={magmaDestinationSmall} alt="Magma Planet" className="imgDestination" />
            );
            break;
          case ('OCEAN'):
            shipBackgroundImage = (
              <img src={oceanPlanet} alt="Ocean Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={oceanDestinationSmall} alt="Ocean Planet" className="imgDestination" />
            );
            break;
          case ('PARADISE'):
            shipBackgroundImage = (
              <img src={paradisePlanet} alt="Paradise Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={paradiseDestinationSmall} alt="Paradise Planet" className="imgDestination" />
            );
            break;
          case ('SWAMP'):
            shipBackgroundImage = (
              <img src={swampPlanet} alt="Swamp Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={swampDestinationSmall} alt="Swamp Planet" className="imgDestination" />
            );
            break;
          case('METALLIC'):
            shipBackgroundImage = (
              <img src={metallicPlanet} alt="Metallic Planet" className="starFieldImage" />
            );
            destinationImage = (
              <img src={metallicDestinationSmall} alt="Metallic Planet" className="imgDestination" />
            );
            break;
        }
      }

      timeLeftText = '';
      btnMinePlanet = (
        <div>
          <button onClick={() => {
            minePlanet(wax, starShip);
          }}>Mine Planet</button>
        </div>
      );

      if (destinationPlanet !== null) {
        if (destinationPlanet.owner === contractAddress) {
          btnClaimPlanet = (
            <div>
              <button onClick={() => {
                let price = (parseFloat(destinationPlanet.max_pool) * (claimplanetc / 100)).toFixed(2);

                if (price < parseFloat(stakedBalance)) {
                  claimPlanet(starShip, starShipData.planet);
                } else {
                    toast.error('Not enough Kyanite',{
                      position: toast.POSITION.TOP_CENTER
                    });
                }
              }}>Claim Planet</button>
            </div>
          );
        }
      }
    }
  } else {
    if (timeDiff > 0) {
      destinationImage = (
        <img src={spaceStationInfo()[starShipData.spacestation].image} alt={spaceStationInfo()[starShipData.spacestation].name} className="imgDestination" />
      );

      shipBackgroundImage = (
        <img src={starfieldSimulation} alt="" className="starFieldImage" />
      );

      statusText = 'En route to: ' + spaceStationInfo()[starShipData.spacestation].name;
    } else {
      statusText = 'Ready';
      timeLeftText = '';

      shipBackgroundImage = (
        <img src={hangarSmall} alt="" className="hangarImage" />
      );

      btnCollect = (
        <div>
          <button onClick={() => {
            claim();
          }}>Claim</button>
        </div>
      );
      btnSendToSpaceStation = (
        <div>
          <button onClick={() => {
            setModalContentState(1);
            openModal();
          }}>Send to Space Station</button>
        </div>
      );
      btnSendOnMission = (
        <div>
          <button onClick={() => {
            setSelectedSpaceStation(starShipData.spacestation);
            setModalContentState(0);
            openModal();
          }}>Send on Mission</button>
        </div>
      );
    }
  }

  switch(starShipData.rarity) {
    case 0:
      shipImage = (
        <img src={shipCommonImage} alt="Common Ship" className="shipImage" />
      );
      shipTypeText = 'Common Ship';
      break;
    case 1:
      shipImage = (
        <img src={shipUncommonImage} alt="Uncommon Ship" className="shipImage" />
      );
      shipTypeText = 'Uncommon Ship';
      break;
    case 2:
      shipImage = (
        <img src={shipRareImage} alt="Rare Ship" className="shipImage" />
      );
      shipTypeText = 'Rare Ship';
      break;
    case 3:
      shipImage = (
        <img src={shipEpicImage} alt="Epic Ship" className="shipImage" />
      );
      shipTypeText = 'Epic Ship';
      break;
    case 4:
      shipImage = (
        <img src={shipLegendaryImage} alt="Legendary Ship" className="shipImage" />
      );
      shipTypeText = 'Legendary Ship';
      break;
    case 5:
      shipImage = (
        <img src={shipMythicImage} alt="Mythic Ship" className="shipImage" />
      );
      shipTypeText = 'Mythic Ship';
      break;
    case 6:
      shipImage = (
        <img src={shipFounderImage} alt="Founder Ship" className="shipImage" />
      );
      shipTypeText = 'Founder Ship';
      break;
    default:
      break;
  }

  return (
    <div>
      <div className="divMobileStarShipMission">
        <div className="divShipImage">
          {shipImage}<br />
          {shipBackgroundImage}
          {destinationImage}
        </div>
        <div className="divShipDescription">
          {shipTypeText} {starShipData.key}<br />
          {spaceStationInfo()[starShipData.spacestation].name}<br />
          <table style={{width: '100%'}} cellSpacing="1" cellPadding="0">
            <tbody>
              <tr>
                <td>Consumption:</td>
                <td style={{textAlign: 'right'}}>{parseFloat(starShipData.consumption).toFixed(2)} Ky/Ps</td>
              </tr>
              <tr>
                <td>Staking Capacity:</td>
                <td style={{textAlign: 'right'}}>{parseFloat(starShipData.staking_capacity).toFixed(2)} Ky/Hr</td>
              </tr>
              <tr>
                <td>Mining Capacity:</td>
                <td style={{textAlign: 'right'}}>{parseFloat(starShipData.mining_capacity).toFixed(2)} Ky/Ps</td>
              </tr>
              <tr>
                <td>Storage Capacity:</td>
                <td style={{textAlign: 'right'}}>{parseFloat(starShipData.storage_capacity * rewardMod).toFixed(2)} Ky</td>
              </tr>
              <tr>
                <td>Stat Speed:</td>
                <td style={{textAlign: 'right'}}>{parseFloat(starShipData.speed).toFixed(2)} min/Ps</td>
              </tr>
              <tr>
                <td>Hourly Speed:</td>
                <td style={{textAlign: 'right'}}>{parseFloat(60/parseFloat(starShipData.speed)).toFixed(2)} Ps/hr</td>
              </tr>
              <tr>
                <td>Max Profit Sector:</td>
                <td style={{textAlign: 'right'}}>{Math.floor((parseFloat(starShipData.storage_capacity * rewardMod) / parseFloat((parseFloat(starShipData.mining_capacity) + (parseFloat(starShipData.consumption)*2)) * rewardMod)) / interdistanc) - 1}</td>
              </tr>
            </tbody>
          </table>
          <hr />
          {statusText}<br />
          {planetText}
          {timeLeftText}<br />
          {btnCollect}
          {btnSendToSpaceStation}
          {btnSendOnMission}
          {btnMinePlanet}
          {btnClaimPlanet}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Select Mission">

        <div className="modalContent">
          {modalContent}
        </div>
        <div className="modalButtons">
          <button onClick={closeModal}>close</button>
        </div>
      </Modal>
    </div>
  );
}
