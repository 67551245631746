/* eslint-disable */
// fetch.worker.js
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (e) => {
    if (e.data === 'close') {
      self.close();
    } else {
      let url = e.data[0];
      let waxId = e.data[1];
      let contract = e.data[2];

      fetch(url, {
        method: 'PUT',
        body: JSON.stringify({waxaccount: waxId}),
        // mode: 'cors',
        headers: {
          'Content-Type': 'text/plain',
          'X-Requested-With': 'JSON'
        }
      })
      .then(response => response.json())
      .then(data => {
        postMessage(data);
      });
    }
  };
};
