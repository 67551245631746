import React, { useState, useEffect } from 'react';
import { Api, JsonRpc } from 'eosjs';
import WorkerBuilder from '../../../workers/worker-builder';
import {contractAddress} from '../../../helpers';
import AttachPartWorker from '../../../workers/attachPart.worker';
import { fetchFile } from "@ffmpeg/ffmpeg";

export default function AvailablePart(props) {
  const component = props.component;
  const i = props.i;
  const url = props.url;
  const videoSource = props.videoSource;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const getComponents = props.getComponents;
  const starShip = props.starShip;
  const statusText = props.statusText;
  const toast = props.toast;
  const getShipData = props.getShipData;




  async function attachPart(wax, assetId, starShip, getComponents) {
    let waxUserAccount = '';
    let player = '';

    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor;
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
          account: 'atomicassets',
          name: 'transfer',
          authorization: [{
              actor: waxUserAccount,
              permission: 'active',
            }],
          data: {
              from: waxUserAccount,
              to: contractAddress,
              asset_ids: [
                  assetId,
              ],
              memo: "Staking Part"
          },
      },{
        account: contractAddress,
        name: 'attachpart',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          starship: starShip,
          part: assetId
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Part Installed!',{
        position: toast.POSITION.TOP_CENTER
      })

      // Wait five seconds and reload the parts
      setTimeout(() => {
        getComponents();
        getShipData(starShip);
      }, 5000);
    } catch(ex) {
      toast.error(ex,{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  let card;
  let button = '';

  if (url !== '') {
    card = (
      <div>
        <img src={url} className="imgComponent" />
      </div>
    );
  } else {
    card = (
      <div>
        <video className="imgComponent" autoPlay loop muted playsInline onLoadedData={(event) => {
          event.target.play();
        }}>
          <source src={videoSource} type="video/mp4" />
        </video>
      </div>
    );
  }

  if (statusText.toUpperCase() === 'READY') {
    button = (
      <button onClick={() => {
        attachPart(wax, component.asset_id, starShip, getComponents);
      }}>Equip</button>
    );
  }

  return (
    <div className="divAvailableComponent">
      {card}
      {component.name}<br />
      {component.data.rarity}<br />
      {button}
    </div>
  );
}
