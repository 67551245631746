import { slide as Menu } from 'react-burger-menu';
import React from 'react';
import LogoMobileMenu from '../../images/StarShipLogoMobileMenu.png';

export default function MobileMenu(props) {
  const setGameScreen = props.setGameScreen;
  const logout = props.logout;
  const availableBalance = props.availableBalance;
  const waxId = props.waxId;
  const stakedBalance = props.stakedBalance;
  const starShipPrice = props.starShipPrice;

    return (
      <div className="divHeader">
        <Menu width={ '50%' }>
          <img src={LogoMobileMenu} alt="Star Ship" className="logoMobileMenu" /><br />
          <span className="spnWaxAccount">$STARSHIP ${parseFloat(starShipPrice).toFixed(4)}</span><br />
          <span className="spnWaxAccount">{waxId}</span><br />

          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(0);
          }}>Space Station</button>
          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(1);
          }}>Hangar</button>
          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(2);
          }}>Missions</button>
          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(3);
          }}>Planets</button>
          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(6);
          }}>Stake \ Withdraw</button>
          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(7);
          }}>Ship Rentals</button>
          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(8);
          }}>Leaderboards</button>
          <button className="mobileMenuButton" onClick={() => {
            setGameScreen(9);
          }}>Shop</button>
          <button className="mobileMenuButton" onClick={async() => {
            await logout();
          }}>Logout</button>

          <div>
            <span>Available Kyanite: {availableBalance.split(' ')[0]}</span>
          </div>

          <div>
            <span>Staked Kyanite: {stakedBalance.split(' ')[0]}</span>
          </div>
        </Menu>
      </div>
    );
}
