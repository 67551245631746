/* eslint-disable */
// fetchRentedShips.worker.js
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (e) => {
    if (e.data === 'close') {
      self.close();
    } else {
      let url = e.data[0];
      let contract = e.data[1];

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(
          {
            json: true,
            code: contract,
            table: 'rentalships',
            scope: contract,
            reverse: false,
            'show_payer': false,
            limit: 1000
          }),
        mode: 'cors',
        headers: {
          'Content-Type': 'text/plain',
          'X-Requested-With': 'JSON'
        }
      })
      .then(response => response.json())
      .then((data) => {
          let availableShips = [];

          for (let i=0; i<data.rows.length; i++) {
            availableShips.push(data.rows[i]);
          }

          postMessage(availableShips);
      });
    }
  };
};
