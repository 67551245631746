import React, { useState, useEffect, useCallback } from 'react';
import {spaceStation, spaceStationInfo, contractAddress} from '../../helpers';
import Select from 'react-select';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import websiteImage from '../../images/website.png';
import dropSpiderButton from '../../images/dropSpiderButton.png';
import Launcher from '../launcher';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import Footer from '../mobileFooter';
import puzzleAd from '../../images/starshipAd_Puzzles.jpg';
import ssuAd from '../../images/ssu_packs_ad_banner.jpg';
import dexyad from '../../images/starshipAd_Dexy.jpg';
import dexyadmobile from '../../images/starshipAd_dexymobile.jpeg';
import Adarea1 from '../adarea1';

export default function Station(props) {
  const getFleet = props.getFleet;
  const fleet = props.fleet;
  const wax = props.wax;
  const toast = props.toast;
  const setGameScreen = props.setGameScreen;
  const stationData = props.stationData;
  const availableBalance = props.availableBalance;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const [selectedSpaceStation, setSelectedSpaceStation] = useState();
  const [youtubeLink, setYoutubeLink] = useState('');
  const [youtubeIFrame, setYoutubeIFrame]  = useState('');
  const [gameButtons, setGameButtons] = useState('');
  const [gameBox, setGameBox] = useState('');
  const [website, setWebsite] = useState('');
  const [desktopWebsite, setDesktopWebsite] = useState('');
  const [desktopBackgroundImage, setDesktopBackgroundImage] = useState('');
  const [updates, setUpdates] = useState([]);

  function setSpaceStation(i) {
    if (typeof stationData !== 'undefined') {
      let youtube = '';
      let ws = '';

      // custom space station design
      switch(i) {
        case 6:
          youtube = 'https://www.youtube.com/embed/videoseries?list=PLKuOu2Y9ehL95G2EGkQj5IDELp2JDcDi0';
          ws = 'https://funkymonkeyfrathouse.com/gameroom/';
          setGameButtons('');
          setGameBox('');
          break;
        case 10:
          ws = 'https://wax.atomichub.io/profile/jali2.wam#listings';
          youtube = 'https://www.youtube.com/embed/videoseries?list=' + stationData[i].youtube_list;
          setGameButtons('');
          setGameBox('');
          break;
        case 19:
          setGameButtons(
            <div className="divGameButtons">
              <img src={dropSpiderButton} alt="Drop Spider" title="Drop Spider" style={{cursor: 'pointer'}} onClick={() => {
                setGameBox(
                  <div className="divGameBox">
                    <iframe frameborder="0" src="https://itch.io/embed-upload/4787200?color=333333" allowfullscreen="true" width="640" height="380"></iframe>
                  </div>
                );
              }} />
            </div>
          );
          ws = 'https://www.archsoftdev.com/';
          break;
        default:
          youtube = 'https://www.youtube.com/embed/videoseries?list=' + stationData[i].youtube_list;
          ws = stationData[i].website;
          setGameButtons('');
          setGameBox('');
          break;
      }

      setSelectedSpaceStation(stationData[i]);
      setYoutubeLink(youtube);
      setYoutubeIFrame(
        <div>
          <iframe width="100%" height="315" src={youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      );
      setWebsite(
        <h3><a href={typeof selectedSpaceStation !== 'undefined' ? ws : ''} rel="noreferrer" target="_blank">Website</a></h3>
      );
      setDesktopWebsite(
        <div className="divImageWebsite">
          <a href={ws} target="_new">
            <img src={websiteImage} alt={stationData[i].website} className="imgWebsite" />
          </a>
        </div>
      );

      setDesktopBackgroundImage(
        <img src={spaceStationInfo()[i].background} className="desktopBackground" />
      );
    }
  }

  async function createShips(spaceStationId) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'createsships',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          spacestation: spaceStationId
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Fleet has been created!',{
        position: toast.POSITION.TOP_CENTER
      });
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function createExtraFleet(spaceStationId) {
    let waxUserAccount = '';
    let player = '';
    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = session.auth.actor;
      player = session.auth.actor
    } else {
      waxUserAccount = wax.userAccount;
      player = wax.userAccount;
    }

    let actions = [
      {
        account: contractAddress,
        name: 'createfleet',
        authorization: [{
          actor: waxUserAccount,
          permission: 'active',
        }],
        data: {
          player: player,
          spacestation: 21
        },
      }
    ];

    try {
      let result;
      if (cloudWallet) {
        result = await wax.api.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        });
      } else {
        let { session } = identity;
        result = await session.transact({
          actions: actions
        }, {
          blocksBehind: 3,
          expireSeconds: 1200,
        })
      }

      toast.success('Fleet has been created!',{
        position: toast.POSITION.TOP_CENTER
      });
      getFleet();
    } catch(ex) {
      toast.error(ex.toString(),{
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  let shipSpaceStations = [];

  if (typeof fleet !== 'undefined' && fleet !== null) {
    fleet.map((ship, i) => {
      let key = i;
      if (!shipSpaceStations.includes(ship.spacestation)) {
        shipSpaceStations.push(ship.spacestation);
      }
    });
  }

  let createFleet = '';
  let createNewFleet = '';
  let advertisement = '';
  let mobileAdvertisement = '';

  if (shipSpaceStations.length === 0) {
    createFleet = (
      <div style={{textAlign: 'center'}}>
        <h2>Select Starting Station</h2>

        {spaceStationInfo().map((ss, i) => {
          return(
            <div key={i} style={{display: 'inline-block', margin: '0.5em'}}>
              <img style={{cursor: 'pointer'}} src={ss.image} onClick={() => {
                createShips(i);
              }} /><br />
              <span style={{color: 'white'}}>{ss.name}</span>
            </div>
          );
        })}

      </div>
    );
  } else {
    createFleet = <h2>{typeof selectedSpaceStation !== 'undefined' ? spaceStationInfo()[selectedSpaceStation.id].name : ''}</h2>
  }





  if (fleet.length <= 9 && fleet.length >= 3) {
    // Write a new futuristic space style button
    let extraFleetButtonStyle = {
      backgroundColor: "#000000",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      margin: "4px 2px",
      cursor: "pointer",
      borderRadius: "12px",
      boxShadow: "0 9px #215494",
      width: "300px",
      height: "40px",
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "Arial",
      textShadow: "0 1px 0 #000000",
      border: "1px solid #000000",
      background: "-webkit-linear-gradient(top, #000000, #000000)",
      background: "-moz-linear-gradient(top, #000000, #000000)",
      background: "-ms-linear-gradient(top, #000000, #000000)",
      background: "-o-linear-gradient(top, #000000, #000000)",
      background: "linear-gradient(to bottom, #000000, #000000)",
      filter:
        "progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#000000, endColorstr=#000000)",
    };
    createNewFleet = (
      <div style={{ textAlign: "center" }}>
        <button
          style={extraFleetButtonStyle}
          className="new-fleet-btn btn btn-primary"
          onClick={() => {
            createExtraFleet();
          }}
        >
          Gain a New Fleet!
        </button>
      </div>
    );
  }


  if (typeof selectedSpaceStation === 'undefined' && shipSpaceStations.length !== 0) {
    advertisement = (
      <div>
         <div className="dexyAdvertisement">
      <a href="https://neftyblocks.com/collection/starshipnfts" target="_new">
        <img style={{height: '80px'}} src={ssuAd} alt='Advertisement' />
      </a>
      </div>
      <div className="dexyAdvertisement">
      <a href="https://dexy.trade" target="_new">
        <img style={{height: '80px'}} src={dexyad} alt='Advertisement' />
      </a>
      </div>
      <div className="divAdvertisement">
        <a href="https://www.deploystarship.com/shop" target="_new">
          <img style={{height: '300px'}} src={puzzleAd} alt='Advertisement' />
        </a>
      </div>
      </div>
      
    );
  }

  if (typeof selectedSpaceStation === 'undefined' && shipSpaceStations.length !== 0) {
    mobileAdvertisement = (
      <div>
         <div className="dexyAdvertisement">
      <a href="https://neftyblocks.com/collection/starshipnfts" target="_new">
        <img style={{height: '80px', marginTop: '50px'}} src={ssuAd} alt='Advertisement' />
      </a>
      </div>
      <div className="dexyAdvertisement">
      <a href="https://dexy.trade" target="_new">
        <img style={{width: '100%', marginTop: '20px'}} src={dexyadmobile} alt='Advertisement' />
      </a>
      </div>
      <div className="divAdvertisement">
        <a href="https://www.deploystarship.com/shop" target="_new">
          <img src={puzzleAd} alt='Advertisement' />
        </a>
      </div>
      </div>
      
    );
  }


  return (
    <div>
      <div className="divDesktopSpaceStation">
        <div className="divDesktopBackground">
          {desktopBackgroundImage}
        </div>
        <div className="divDesktopContent">
          {advertisement}
          {createFleet}
          {createNewFleet}
          {gameButtons}
          {gameBox}
          {desktopWebsite}
          <div className="divYoutubePlaylist">
            {youtubeIFrame}
          </div>
        </div>
        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <div className="desktopStationButtons">
          <h3 style={{color: 'white', marginTop: '0em'}}>Select Space Station to view Main Hub</h3>
            {shipSpaceStations.map((spaceStationId, i) => {
              return (
                  <img key={i} src={spaceStationInfo()[spaceStationId].image} onClick={() => {
                    setSpaceStation(spaceStationId);
                  }} />
              );
            })}
          </div>
          <img src={controlPanelBackground} alt="" />
          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
              let waxUserAccount = '';
              let player = '';
              if (anchorWax) {
                let { session } = identity;
                waxUserAccount = session.auth.actor;
                player = session.auth.actor;
              } else {
                waxUserAccount = wax.userAccount;
                player = wax.userAccount;
              }

              let actions = [
                {
                  account: contractAddress,
                  name: 'claim',
                  authorization: [{
                    actor: waxUserAccount,
                    permission: 'active',
                  }],
                  data: {
                    player: player
                  },
                }
              ];

              try {
                let result;
                if (cloudWallet) {
                  result = await wax.api.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  });
                } else {
                  let { session } = identity;
                  result = await session.transact({
                    actions: actions
                  }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                  })
                }

                toast.success('Kyanite Claimed!',{
                  position: toast.POSITION.TOP_CENTER
                });
              } catch(ex) {
                toast.error(ex,{
                  position: toast.POSITION.TOP_CENTER
                });
              }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>
      </div>

      <div className="divMobileSpaceStation">
        <div>
          <h1 className="screenTitle">Space Station</h1>
        </div>
       
        <div className="mobileStationButtons">
          <h3 style={{color: 'white', marginTop: '0em'}}>Select Space Station to view Main Hub</h3>
          {shipSpaceStations.map((spaceStationId, i) => {
            return (
              <img key={i} src={spaceStationInfo()[spaceStationId].image} onClick={() => {
                setSpaceStation(spaceStationId);
              }} />
            )
          })}
        </div>

        <div className="divMobileSpaceStationContent">
          {mobileAdvertisement}
          {createFleet}
          {createNewFleet}
          {gameButtons}
          {gameBox}
          {website}
          {youtubeIFrame}
        </div>
      </div>
    </div>
  );
}
