
import React, { useState, useEffect } from 'react';

export default function Adarea1(props) {
  
    const autorefresh = false;
    const [key, setKey] = useState(0);
  
    useEffect(() => {
      if (!autorefresh) return;
      const interval = setInterval(() => {
        setKey(key + 1);
      }, 30000);
      return () => clearInterval(interval);
    }, [key]);


  
    return (
        <iframe key={key} title="ad-unit" data-aa='2163774' src='//acceptable.a-ads.com/2163774' style={{border:"0px", padding:"0px", paddingTop:"50px", width:"100%", height:"100%", overflow:"hidden", backgroundColor: "transparent"}}></iframe>
    );
  }