import React, { useState, useEffect, useCallback } from 'react';
import spacestationIcon from '../../images/spacestationIcon.png';
import hangarIcon from '../../images/hangarIcon.png';
import missionsIcon from '../../images/missionsIcon.png';
import planetsIcon from '../../images/planetsIcon.png';
import kyaniteIcon from '../../images/kyaniteIcon.png';
import rentalIcon from '../../images/rentalIcon.png';
import leaderboardIcon from '../../images/leaderboardIcon.png';
import shopIcon from '../../images/shopIcon.png';

export default function Launcher(props) {
  const setGameScreen = props.setGameScreen;

  return (
    <div className="divLauncher">
      <img src={spacestationIcon} alt="Space Stations" title="Space Stations" onClick={() => {
        setGameScreen(0);
      }} />
      <img src={hangarIcon} alt="Hangar" title="Hangar" onClick={() => {
        setGameScreen(1);
      }} />
      <img src={missionsIcon} alt="Missions" title="Missions" onClick={() => {
        setGameScreen(2);
      }} />
      <img src={planetsIcon} alt="Planets" title="Planets" onClick={() => {
        setGameScreen(3);
      }} />
      <img src={kyaniteIcon} alt="Stake\Withdraw Kyanite" title="Stake\Withdraw Kyanite" onClick={() => {
        setGameScreen(6);
      }} />
      <img src={rentalIcon} alt="Rental Ships" title="Rental Ships" onClick={() => {
        setGameScreen(7);
      }} />
      <img src={leaderboardIcon} alt="Leaderboards" title="Leaderboards" onClick={() => {
        setGameScreen(8);
      }} />
      <img src={shopIcon} alt="Leaderboards" title="Shop" onClick={() => {
        setGameScreen(9);
      }} />
    </div>
  );
}
