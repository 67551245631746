import React, { useState, useEffect } from 'react';
import {
	toTitleCase,
	spaceStationInfo,
	convertUnixTime,
	getEndPoints,
	getPlanetDictionary,
	quadrant,
	sessionEndpoint, contractAddress
} from '../../helpers';
import WorkerBuilder from '../../workers/worker-builder';
import FetchPlayerOwnedPlanetsWorker from '../../workers/fetchPlayerOwnedPlanets.worker';
import gasPlanet from '../../images/gas.png';
import crystalPlanet from '../../images/crystal.png';
import greenlandPlanet from '../../images/greenland.png';
import icePlanet from '../../images/ice.png';
import desertPlanet from '../../images/desert.png';
import swampPlanet from '../../images/swamp.png';
import oceanPlanet from '../../images/ocean.png';
import magmaPlanet from '../../images/magma.png';
import paradisePlanet from '../../images/paradise.png';
import metallicPlanet from '../../images/metallic.png';
import controlPanelBackground from '../../images/controlPanelBackground.png';
import Launcher from '../launcher';
import Updates from '../updates';
import InfoPanel from '../infoPanel';
import Adarea1 from '../adarea1';

export default function Station(props) {
  const setGameScreen = props.setGameScreen;
  const wax = props.wax;
  const anchorWax = props.anchorWax;
  const cloudWallet = props.cloudWallet;
  const identity = props.identity;
  const toast = props.toast;
  const availableBalance = props.availableBalance;
  const stakedBalance = props.stakedBalance;
  const userBalance = props.userBalance;
  const [playerOwnedPlanets, setPlayerOwnedPlanets] = useState([]);
  const [playerCommissionTotal, setPlayerCommissionTotal] = useState(0);

  let totalpool = 0;
  let totalmaxpool = 0;



  useEffect(() => {
    let waxUserAccount = '';

    if (anchorWax) {
      let { session } = identity;
      waxUserAccount = `${session.auth.actor}`;
    } else {
      waxUserAccount = wax.userAccount;
    }

    let fetchPlayerOwnedPlanetsInstance = new WorkerBuilder(FetchPlayerOwnedPlanetsWorker);
    fetchPlayerOwnedPlanetsInstance.onmessage = (message) => {
      if (message) {
        fetchPlayerOwnedPlanetsInstance.postMessage('close');

        if (typeof message.data !== 'undefined') {
          if (typeof message.data.rows !== 'undefined') {
            setPlayerOwnedPlanets(message.data.rows);

            let commissionTotal = 0;

            for(let i=0; i<message.data.rows.length; i++) {
              if (message.data.rows[i].next_reset > new Date().setHours(0,0,0,0) / 1000) {
                commissionTotal = parseFloat(commissionTotal) + ((parseFloat(message.data.rows[i].max_pool).toFixed(2) - parseFloat(message.data.rows[i].pool).toFixed(2)) * 0.05);
              }
            }

            setPlayerCommissionTotal(commissionTotal);
          }
        }
      }
    };

    // fetchPlayerOwnedPlanetsInstance.postMessage([`${getEndPoints()[Math.floor(Math.random() * getEndPoints().length)]}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
    fetchPlayerOwnedPlanetsInstance.postMessage([`${sessionEndpoint}/v1/chain/get_table_rows`, waxUserAccount, contractAddress]);
  }, [playerCommissionTotal]);

  return (
    <div>
      <div className="divPlanetsDesktop">
        <div className="divDesktopBackground">
          <img src="planets.jpg" className="desktopBackground" />
        </div>
        <div className="divDesktopContent">
          <h2>My Planets</h2>
          <h2>Today's Commission: {playerCommissionTotal.toFixed(2)}</h2>
          <h2>
            Total Pool:

            { 
              
              playerOwnedPlanets.forEach(element => {
                if(element.next_reset < new Date()/ 1000){
                  //convert element.pool to float
                  //add to totalpool
                  totalpool = parseFloat(totalpool) + parseFloat(element.max_pool);
                } else {
                  totalpool = parseFloat(totalpool) + parseFloat(element.pool);
                }
              }) 
            }
            {totalpool.toFixed(0)}
          </h2>
          <h2>
            Total Max Pool:
            { 
              playerOwnedPlanets.forEach(element => {
                  //convert element.pool to float
                  //add to totalpool
                  totalmaxpool = parseFloat(totalmaxpool) + parseFloat(element.max_pool);
              }) 
            }
            {totalmaxpool.toFixed(0)}

          </h2>
          <div className="divPlanetsContent" style={{paddingTop:"3em"}}>
            {playerOwnedPlanets.map((playerOwnedPlanet, i) => {
              let planetImage;
              let planetSpaceStation = '';
              let planetQuadrant = '';
              let planetSector = '';
              let planetSystem = '';

              let planetData = getPlanetDictionary(playerOwnedPlanet.id);

              if (typeof planetData !== 'undefined') {
                planetSpaceStation = (
                  <div>
                    {spaceStationInfo()[planetData.spaceStation].name}
                  </div>
                );
                planetQuadrant = (
                  <div>
                    {quadrant(planetData.quadrant)} Quadrant
                  </div>
                );
                planetSector = (
                  <div>
                    Sector {planetData.sector}
                  </div>
                );
                planetSystem = (
                  <div>
                    System {planetData.system}
                  </div>
                );
              }

              switch(playerOwnedPlanet.type.toUpperCase()) {
                case ('CRYSTAL'):
                  planetImage = <img src={crystalPlanet} alt="Crystal" />
                  break;
                case ('GAS'):
                  planetImage = <img src={gasPlanet} alt="Gas" />
                  break;
                case ('DESERT'):
                  planetImage = <img src={desertPlanet} alt="Desert" />
                  break;
                case ('GLACIER'):
                  planetImage = <img src={icePlanet} alt="Ice" />
                  break;
                case ('MAGMA'):
                  planetImage = <img src={magmaPlanet} alt="Magma" />
                  break;
                case ('SWAMP'):
                  planetImage = <img src={swampPlanet} alt="Swamp" />
                  break;
                case ('OCEAN'):
                  planetImage = <img src={oceanPlanet} alt="Ocean" />
                  break;
                case ('GRASSLAND'):
                  planetImage = <img src={greenlandPlanet} alt="Grassland" />
                  break;
                case ('PARADISE'):
                  planetImage = <img src={paradisePlanet} alt="Paradise" />
                  break;
                case ('METALLIC'):
                  planetImage = <img src={metallicPlanet} alt="Metallic" />
                  break;
              }

              let commission;

              if (playerOwnedPlanet.next_reset > new Date().setHours(0,0,0,0) / 1000) {
                commission = ((parseFloat(playerOwnedPlanet.max_pool).toFixed(2) - parseFloat(playerOwnedPlanet.pool).toFixed(2)) * 0.05).toFixed(2);
              } else {
                commission = 0;
              }

              let today = new Date();
              let tomorrow = new Date();
              tomorrow.setDate(today.getDate() + 1);
              tomorrow.setHours(-4,0,0,0);

              return (
                <div key={i} className="planetCard">
                  <div>
                    <div style={{display: 'inline-block', width: '25%'}}>
                      <span style={{float: 'left'}}>{planetImage}</span>
                    </div>
                    <div style={{display: 'inline-block', width: '75%'}}>
                      <span style={{fontSize: '1.5em', float: 'right'}}>{toTitleCase(playerOwnedPlanet.type)}<br />
                      {playerOwnedPlanet.id}</span>
                    </div>
                  </div>
                  {planetSpaceStation}
                  {planetQuadrant}
                  {planetSector}
                  {planetSystem}
                  <div>
                    Pool: {playerOwnedPlanet.next_reset > today / 1000 ? parseFloat(playerOwnedPlanet.pool).toFixed(0) : parseFloat(playerOwnedPlanet.max_pool).toFixed(0)} / {parseFloat(playerOwnedPlanet.max_pool).toFixed(0)}
                  </div>
                  <div>
                    Resets: {(playerOwnedPlanet.next_reset > new Date() / 1000) ? convertUnixTime(playerOwnedPlanet.next_reset) : convertUnixTime(tomorrow / 1000)}
                  </div>
                  <a href={"https://wax.atomichub.io/explorer/asset/" + playerOwnedPlanet.nft} target="_new">NFT</a><br />
                   Commission: {commission}<br />
                </div>
              );
            })}
          </div>
        </div>
        <div className="divControlPanel">
          <Updates />
          <InfoPanel userBalance={userBalance} starShipPrice={props.starShipPrice} />

          <div className="desktopStationButtons">
          </div>
          <img src={controlPanelBackground} alt="" />
          <div className="divTotalKyanite">
            <span>Total Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{availableBalance}</span>
          </div>
          <div className="divStakedKyanite">
            <span>Staked Kyanite</span>
            <span style={{float: 'right', paddingRight: '0.25em'}}>{stakedBalance.split(' ')[0]}</span>
          </div>
          <div className="divClaimRewards" onClick={async () => {
            let waxUserAccount = '';
            let player = '';
            if (anchorWax) {
              let { session } = identity;
              waxUserAccount = session.auth.actor;
              player = session.auth.actor;
            } else {
              waxUserAccount = wax.userAccount;
              player = wax.userAccount;
            }

            let actions = [
              {
                account: contractAddress,
                name: 'claim',
                authorization: [{
                  actor: waxUserAccount,
                  permission: 'active',
                }],
                data: {
                  player: player
                },
              }
            ];

            try {
              let result;
              if (cloudWallet) {
                result = await wax.api.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                });
              } else {
                let { session } = identity;
                result = await session.transact({
                  actions: actions
                }, {
                  blocksBehind: 3,
                  expireSeconds: 1200,
                })
              }

              toast.success('Kyanite Claimed!',{
                position: toast.POSITION.TOP_CENTER
              });
            } catch(ex) {
              toast.error(ex,{
                position: toast.POSITION.TOP_CENTER
              });
            }
          }}>
            Claim
          </div>
          <Launcher setGameScreen={setGameScreen} />
        </div>
      </div>
      <div className="divPlanetsMobile">
        <h1 className="screenTitle">Planets</h1>
        <div className="divPlanetsContent">
          <h2>Today's Commission: {playerCommissionTotal.toFixed(2)}</h2>
          {playerOwnedPlanets.map((playerOwnedPlanet, i) => {
            let planetImage;
            let planetSpaceStation = '';
            let planetQuadrant = '';
            let planetSector = '';
            let planetSystem = '';

            let planetData = getPlanetDictionary(playerOwnedPlanet.id);

            if (typeof planetData !== 'undefined') {
              planetSpaceStation = (
                <div>
                  {spaceStationInfo()[planetData.spaceStation].name}
                </div>
              );
              planetQuadrant = (
                <div>
                  {quadrant(planetData.quadrant)} Quadrant
                </div>
              );
              planetSector = (
                <div>
                  Sector {planetData.sector}
                </div>
              );
              planetSystem = (
                <div>
                  System {planetData.system}
                </div>
              );
            }

            switch(playerOwnedPlanet.type.toUpperCase()) {
              case ('CRYSTAL'):
                planetImage = <img src={crystalPlanet} alt="Crystal" />
                break;
              case ('GAS'):
                planetImage = <img src={gasPlanet} alt="Gas" />
                break;
              case ('DESERT'):
                planetImage = <img src={desertPlanet} alt="Desert" />
                break;
              case ('GLACIER'):
                planetImage = <img src={icePlanet} alt="Ice" />
                break;
              case ('MAGMA'):
                planetImage = <img src={magmaPlanet} alt="Magma" />
                break;
              case ('SWAMP'):
                planetImage = <img src={swampPlanet} alt="Swamp" />
                break;
              case ('OCEAN'):
                planetImage = <img src={oceanPlanet} alt="Ocean" />
                break;
              case ('GRASSLAND'):
                planetImage = <img src={greenlandPlanet} alt="Grassland" />
                break;
              case ('PARADISE'):
                planetImage = <img src={paradisePlanet} alt="Paradise" />
                break;
              case ('METALLIC'):
                planetImage = <img src={metallicPlanet} alt="Metallic" />
                break;
            }

            let commission;
            let today = new Date();
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 2);
            tomorrow.setHours(-4,0,0,0);

            if (playerOwnedPlanet.next_reset > new Date().setHours(0,0,0,0) / 1000) {
              commission = ((parseFloat(playerOwnedPlanet.max_pool).toFixed(2) - parseFloat(playerOwnedPlanet.pool).toFixed(2)) * 0.05).toFixed(2);
            } else {
              commission = 0;
            }

            return (
                <div key={i} className="planetCard">
                  <div>
                    <div style={{display: 'inline-block', width: '25%'}}>
                      <span style={{float: 'left'}}>{planetImage}</span>
                    </div>
                    <div style={{display: 'inline-block', width: '75%'}}>
                      <span style={{fontSize: '1.5em', float: 'right'}}>{toTitleCase(playerOwnedPlanet.type)}<br />
                      {playerOwnedPlanet.id}</span>
                    </div>
                  </div>
                  {planetSpaceStation}
                  {planetQuadrant}
                  {planetSector}
                  {planetSystem}
                  <div>
                    Pool: {playerOwnedPlanet.next_reset > today / 1000 ? parseFloat(playerOwnedPlanet.pool).toFixed(0) : parseFloat(playerOwnedPlanet.max_pool).toFixed(0)} / {parseFloat(playerOwnedPlanet.max_pool).toFixed(0)}
                  </div>
                  <div>
                    Resets: {(playerOwnedPlanet.next_reset > new Date() / 1000) ? convertUnixTime(playerOwnedPlanet.next_reset) : convertUnixTime(tomorrow / 1000)}
                  </div>
                  <a href={"https://wax.atomichub.io/explorer/asset/" + playerOwnedPlanet.nft} target="_new">NFT</a><br />
                  {playerOwnedPlanet.type}<br />
                   Commission: {commission}
                </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
